import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { CustomListItem } from './NotificationListItem';
import { trashBin } from 'ionicons/icons';
import {
  deleteAllNotifications,
  getNotifications,
} from '../services/notificationService';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useChangeContext } from '../context/ChangeDetection';
import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { getUserDoc } from '../services/userService';
import { useAuthContext } from '../context/Auth';

const StyledNotificationWrapper = styled.div<any>`
  ${({ type }) =>
    type !== 'modal' &&
    `position: absolute;
    top: 0;
    z-index: 100;
    right: 0;
    min-width: 100px;
    width: fit-content;
    `};
`;
const StyledPTag = styled.p`
  text-align: center;
`;
const StyledBox = styled(Box)`
  padding: 10px;
`;

type ListComponentType = {
  type?: string;
  closeNotificationList: () => void;
};

export const ListComponent = ({
  type = '',
  closeNotificationList,
}: ListComponentType) => {
  const [notifications, setNotifications] = useState<any>();
  const { setChange, change } = useChangeContext();
  const { currentUser, setCurrentUser, loading } = useAuthContext();
  const { t } = useTranslation();
  useEffect(() => {
    try {
      if (currentUser) {
        getNotifications().then((notifications) => {
          setNotifications(notifications);
        });
        getUserDoc(currentUser.uid).then(setCurrentUser);
      }
    } catch (error) {}
  }, [change]);
  return (
    <>
      {!loading && notifications && (
        <StyledNotificationWrapper type={type}>
          <StyledBox
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            <nav aria-label="main notifications folders">
              {notifications.length > 0 ? (
                <List>
                  {notifications.map((notification, index) => (
                    <CustomListItem
                      key={`listItem-${index}`}
                      notification={notification}
                    />
                  ))}
                </List>
              ) : (
                <StyledPTag>{t('noNotifications')}</StyledPTag>
              )}
            </nav>
            <Divider />
            {notifications.length > 0 && (
              <List>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={(e) => {
                      e.preventDefault();
                      deleteAllNotifications();
                      setChange(new Date());
                      closeNotificationList();
                    }}
                  >
                    <ListItemIcon>
                      <IonIcon slot="icon-only" icon={trashBin} />
                    </ListItemIcon>
                    <ListItemText primary={t('deleteAll')} />
                  </ListItemButton>
                </ListItem>
              </List>
            )}
          </StyledBox>
        </StyledNotificationWrapper>
      )}
    </>
  );
};

import { IonCard } from '@ionic/react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import css from '@styled-system/css';
import { TextWrapper } from '../components/TextWrapper';
import { Anchor } from '../elements/Anchor';
import { Headline } from '../elements/Headlines';
import { Input } from '../elements/Input';
import { Button } from '../elements/Button';
import { Text } from '../elements/Text';
import { MODAL_TYPES, useGlobalModalContext } from '../context/GlobalModal';
import { useTranslation } from 'react-i18next';

type TransparentCard = {
  joinChallenge: (pin: string) => void;
  className?: string;
  authenticated?: boolean;
};

const StyledIonCard = styled(IonCard)`
  max-width: 350px;
  width: 290px;
  margin: 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: 100%;
  border-style: dotted;
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  ${css({
    maxWidth: ['290px', '350px', '400px', '320px', '400px'],
    flex: ['0 0 100%', '0 0 100%', '0 0 100%', '1 0 25%', '20%'],
    width: ['290px', '350px', '400px', '320px', '320px'],
  })}
`;

const ContentWrapper = styled.div`
  position: relative;
  justify-content: space-around;
  display: flex;
  height: 420px;
  margin: 0;
  align-items: center;
  flex-direction: column;
`;

const Flex = styled.div`
  display: flex;
  margin: 0px 20px;
`;

export const TransparentCard = ({
  joinChallenge,
  className,
  authenticated = false,
}: TransparentCard) => {
  const { t } = useTranslation();
  const [pin, setPin] = useState('');
  const inputChange = (event) => {
    setPin(event.target.value);
  };

  const { showModal } = useGlobalModalContext();
  return (
    <StyledIonCard className={className}>
      <ContentWrapper>
        <TextWrapper
          children={
            <p>
              {t('click')}{' '}
              <Anchor
                onClick={() => {
                  if (authenticated) {
                    showModal('COMPETITION_IDEA_MODAL', {
                      actionType: 'Create',
                      objectType: 'Competition',
                    });
                  } else {
                    showModal(MODAL_TYPES.AUTH_MODAL, { headerStyle: 'large' });
                  }
                }}
                href="#"
                text={t('here')}
              />{' '}
              {t('toCreateNewChallenge')}
            </p>
          }
        />
      </ContentWrapper>
    </StyledIonCard>
  );
};

import { TextField } from '@mui/material';
import { InputType } from '../types/form';

export const Input = ({
  className,
  label,
  type = 'text',
  variant = 'standard',
  register = {},
  multiline = false,
  ...rest
}: InputType) => {
  return (
    <TextField
      {...register}
      className={className}
      label={label}
      variant={variant}
      type={type}
      {...rest}
      multiline={multiline}
    />
  );
};

import styled from 'styled-components';
import { Button } from '../elements/Button';
import { IonIcon } from '@ionic/react';
import { chevronBack, chevronForward } from 'ionicons/icons';

type PaginateType = {
  previous: Function;
  next: Function;
  disableNext: Boolean;
  disablePrev: Boolean;
};

const BackButton = styled(Button)`
  z-index: 1;
  height: 100%;
  width: 50px;
  position: fixed;
  left: 0;
  :hover {
    background: var(--ion-color-primary);
    opacity: 0.1;
  }
`;

const ForwardButton = styled(Button)`
  z-index: 1;
  height: 100%;
  position: fixed;
  width: 50px;
  right: 0;
  :hover {
    background: var(--ion-color-primary);
    opacity: 0.1;
  }
`;

const PaginateContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;

const Paginate = ({
  previous,
  next,
  disableNext = false,
  disablePrev = false,
}: PaginateType) => {
  return (
    <PaginateContainer>
      <BackButton
        fill="clear"
        disabled={disablePrev}
        onClick={previous}
        children={<IonIcon size="large" icon={chevronBack} />}
      />
      <ForwardButton
        fill="clear"
        disabled={disableNext}
        onClick={next}
        children={<IonIcon size="large" icon={chevronForward} />}
      />
    </PaginateContainer>
  );
};

export default Paginate;

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarsIcon from '@mui/icons-material/Stars';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CommentIcon from '@mui/icons-material/Comment';
import { IonIcon, IonRouterLink } from '@ionic/react';
import { changeNotificationToRead } from '../services/notificationService';
import Styled from 'styled-components';
import { trashBin } from 'ionicons/icons';
import { deleteNotification } from '../services/notificationService';
import { useChangeContext } from '../context/ChangeDetection';
import { useTranslation } from 'react-i18next';
const IconType = {
  NEW_PARTICIPANT: GroupAddIcon,
  NEW_LOVES: FavoriteIcon,
  NEW_COMMENT: CommentIcon,
  NEW_BADGE: StarsIcon,
  NEW_IDEA: LightbulbIcon,
};

const StyledListItemText = Styled(ListItemText)<any>`
span{
  color: ${(props) => props.read === 'true' && 'gray'}
}
`;

const StyledListIcon = Styled(ListItemIcon)<any>`
  svg{
    fill: ${(props) => props.read === 'true' && 'gray'}

  }

`;

const MapNotification = (notification) => {
  const { t } = useTranslation();

  const {
    type,
    competitionId,
    ideaId,
    competitionTitle,
    ideaTitle,
    firstName,
    lastName,
    ideaDescription,
    badgeTitle,
  } = notification;
  const NotificationType = {
    NEW_PARTICIPANT: {
      content: `${firstName} ${lastName} ${t(
        'joinedNotification'
      )} ${competitionTitle}`,
      goto: `/challenge/${competitionId}`,
    },
    NEW_LOVES: {
      content: `${firstName} ${lastName}: ${ideaTitle}`,
      goto: `/idea/${competitionId}/${ideaId}`,
    },
    NEW_COMMENT: {
      content: `${firstName} ${lastName}: ${ideaTitle}`,
      goto: `/idea/${competitionId}/${ideaId}`,
    },
    NEW_BADGE: { content: `${t('newBadge')} ${badgeTitle}`, goto: `/profile` },
    NEW_IDEA: {
      content: `${firstName} ${lastName}: ${ideaTitle}`,
      goto: `/idea/${competitionId}/${ideaId}`,
    },
  };
  return NotificationType[type];
};
const StyledListItemButton = Styled(ListItemButton)`
color:gray;
justify-content:flex-end !important;
`;

const StyledListItem = Styled(ListItem)`
width:100%;
`;
export const CustomListItem = ({ notification }) => {
  const { type, read } = notification;
  const { setChange } = useChangeContext();
  const Mappednotification = MapNotification(notification);

  const Icon = IconType[type];

  return (
    <>
      {Mappednotification && (
        <StyledListItem disablePadding>
          <IonRouterLink
            onClick={(e) => {
              changeNotificationToRead({ notificationId: notification.id });
              setChange(new Date());
            }}
            href={`${Mappednotification.goto}`}
          >
            <StyledListItemButton>
              <StyledListIcon read={read ? 'true' : 'false'}>
                <Icon />
              </StyledListIcon>
              <StyledListItemText
                read={read ? 'true' : 'false'}
                primary={Mappednotification.content}
              />
            </StyledListItemButton>
          </IonRouterLink>
          <StyledListItemButton
            onClick={(e) => {
              e.preventDefault();
              deleteNotification(notification.id);
              setChange(new Date());
            }}
          >
            <IonIcon slot="icon-only" icon={trashBin} />
          </StyledListItemButton>
        </StyledListItem>
      )}
    </>
  );
};

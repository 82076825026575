import StarsRoundedIcon from '@mui/icons-material/StarsRounded';
import styled from 'styled-components';
import { BadgeType } from '../types/badge';

const BadgeDiv = styled.div`
  max-width: 230px;
  max-height: 230px;
  min-width: 160px;
  min-height: 160px;
  padding: 5px;
  padding-top: 20px;
  text-align: center;
  border-radius: 6px;
  border: 1px solid gainsboro;
  border-style: groove;
`;

const BadgeTitle = styled.div<any>`
  margin-top: 20px;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  color: ${({ active }) => active === 'false' && 'lightgrey !important'};
`;

const BadgeInfo = styled.div<any>`
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  color: ${({ active }) => active === 'false' && 'lightgrey !important'};
`;

const Icon = styled<any>(StarsRoundedIcon)`
  fill: ${({ active }) => active === 'false' && 'lightgrey !important'};
`;

export const Badge = ({ iconActive, title, text }: BadgeType) => {
  return (
    <BadgeDiv>
      <Icon
        active={iconActive ? 'true' : 'false'}
        fontSize="large"
        color={iconActive ? 'inherit' : 'disabled'}
      />
      <BadgeTitle active={iconActive ? 'true' : 'false'}>{title}</BadgeTitle>
      <BadgeInfo active={iconActive ? 'true' : 'false'}>{text}</BadgeInfo>
    </BadgeDiv>
  );
};

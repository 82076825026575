import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  useIonToast,
  IonIcon,
} from '@ionic/react';

import { useGlobalModalContext, MODAL_TYPES } from '../context/GlobalModal';
import { DropDown } from '../elements/DropDown';
import { Button } from '../elements/Button';
import { CardType } from '../types/card';
import { Image } from '../elements/Image';
import styled from 'styled-components';
import css from '@styled-system/css';
import { Avatar } from '../elements/Avatar';
import { Label } from '../elements/Label';
import {
  bulb,
  people,
  stopwatch,
  chatbox,
  ellipsisVertical,
  pencil,
  personRemove,
  personAdd,
  trashBin,
} from 'ionicons/icons';
import { hostAuthorMap } from '../utils/mappers';
import { colors } from '../utils/colorPalette';
import { HeartLabel } from './HeartLabel';
import {
  getCompetition,
  removeUserFromCompetition,
  deleteCompetition,
} from '../services/competitionService';
import { deleteIdea, getIdea } from '../services/ideaService';
import { useAuthContext } from '../context/Auth';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isAuthor, isHost } from '../utils/checkRole';
import { useAppContext } from '../context/Shared';
import { useTranslation } from 'react-i18next';
import { useChangeContext } from '../context/ChangeDetection';

const StyledIonCard = styled(IonCard)<Pick<CardType, 'cardColor'>>`
  background-color: ${({ cardColor }) => cardColor && colors[cardColor]};
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 10px;
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  ${css({
    flexDirection: ['column', 'column', 'row', 'row'],
    height: [
      'fit-content !important',
      'fit-content !important',
      '300px !important',
      '500px !important',
    ],
  })}
`;

const StyledAvatar = styled(Avatar)`
  position: absolute;
  z-index: 100;
  ${css({
    top: ['90%', '90%', '50%', '50%'],
    left: ['unset', 'unset', '92%', '95%'],
  })}
`;
const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  ${css({
    height: ['50%', '50%', '100%', '100%'],
    flex: ['2', '2', '1 1 45%', '1 1 45%'],
  })}
`;
const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  -ms-interpolation-mode: bicubic;
`;
const StyledLabel = styled(Label)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
`;
const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 5px;
  top: 50px;
  left: 0;
  width: fit-content;
`;
const TitleWithLineClamp = styled(IonCardTitle)`
  width: 100%;
  padding-left: 15px;
`;
const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 30%;
  padding: 0 2;
  ${css({
    width: ['100%', '100%', '100%', '30%'],
    height: ['95%', '95%', '95%', '95%'],
    flex: ['2', '2', '1 1 55%', '1 1 55%'],
  })}
`;
const Description = styled.p`
  width: 100%;
  height: 90%;
  overflow: auto;
  ${css({
    overflow: ['hidden', 'hidden', 'auto', 'auto'],
    height: ['100%', '100%', '100%', '100%'],
    padding: '0 12px 0 17px',
  })}
`;
const StyledIonCardContent = styled(IonCardContent)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
`;
const StyledIcon = styled(IonIcon)`
  font-size: x-large;
`;

const ChildrenWrapper = styled.div`
  display: flex;
`;

const StyledIonCardHeader = styled(IonCardHeader)`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
`;

const StyledVideo = styled.video`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const ZoomImageContainer = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const BigCard = ({
  title = '',
  backgroundImage = 'assets/wlimaskot.jpg',
  user,
  participants = [],
  description = '',
  ideaCount = 0,
  daysLeft = 0,
  ideaId,
  cardState = 'active',
  cardColor = 'green',
  cardType = 'challenge',
  commentCount = 0,
  lovedBy = [],
  className,
  competitionId,
  competitionPin,
  video,
}: CardType) => {
  const { showModal } = useGlobalModalContext();
  const { change } = useChangeContext();
  const userMapped = hostAuthorMap({ user: user });
  const { currentUser } = useAuthContext() as any;

  const [present, dismiss] = useIonToast();
  const history = useHistory();
  const [allCompetitionData, setAllCompetitionData] = useState(null) as any;
  const [allIdeaData, setAllIdeaData] = useState(null) as any;
  const { device } = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (competitionId) {
      getCompetition({ competitionId }).then((res) => {
        setAllCompetitionData(res.data);
      });
    }
    if (ideaId) {
      getIdea({ competitionId, ideaId }).then((res) => {
        setAllIdeaData(res.data);
      });
    }
  }, [competitionId, ideaId, change]);

  const leaveCompetition = async () => {
    await removeUserFromCompetition({
      userId: currentUser.uid,
      competitionId,
    });
    history.replace('/');
    window.location.reload();
  };
  const leaveCompetitionConfirm = () => {
    present({
      position: 'middle',
      color: 'warning',
      message: t('wantToLeave'),
      buttons: [
        { text: t('yes'), handler: leaveCompetition },
        { text: t('no'), handler: dismiss },
      ],
    });
  };
  const deleteChallenge = async () => {
    await deleteCompetition({
      competitionId,
    });
    history.replace('/');
    window.location.reload();
  };

  const deleteIdeaAction = async () => {
    await deleteIdea({
      competitionId,
      ideaId,
    });
    history.go(-1);
  };

  const deleteObjectConfirm = (type) => {
    present({
      position: 'middle',
      color: 'warning',
      message: t('wantToDelete'),
      buttons: [
        {
          text: t('yes'),
          handler: type === 'competition' ? deleteChallenge : deleteIdeaAction,
        },
        { text: t('no'), handler: dismiss },
      ],
    });
  };

  const openInviteModal = () => {
    showModal(MODAL_TYPES.GENERATE_PIN_MODAL, {
      competitionPin,
      participants,
      competitionId,
      hostId: allCompetitionData.host.id,
    });
  };

  const startEditing = async () => {
    const isChallenge = cardType === 'challenge';
    showModal(MODAL_TYPES.COMPETITION_IDEA_MODAL, {
      competition: allCompetitionData,
      idea: !isChallenge && allIdeaData,
      actionType: 'Edit',
      objectType: isChallenge ? 'Competition' : 'Idea',
    });
  };

  return (
    <StyledIonCard className={className} cardColor={cardColor}>
      {cardType === 'challenge' ? (
        <LabelWrapper>
          <StyledLabel text={ideaCount + t('ideaText')}>
            <StyledIcon icon={bulb} />
          </StyledLabel>
          <StyledLabel
            text={participants?.length + t('participantText')}
            onClick={(e) => {
              e.preventDefault();
              openInviteModal();
            }}
          >
            <StyledIcon icon={people} />
          </StyledLabel>
          <StyledLabel text={daysLeft + t('dayText')}>
            <StyledIcon icon={stopwatch} />
          </StyledLabel>
        </LabelWrapper>
      ) : (
        <LabelWrapper>
          <HeartLabel
            canLike={
              !isAuthor({
                authorId: allIdeaData?.author?.id,
                authId: currentUser?.uid,
              })
            }
            lovedBy={lovedBy}
            competitionId={competitionId}
            ideaId={ideaId}
            text={t('lovesText')}
          />
          <StyledLabel text={commentCount + t('commentsText')}>
            <StyledIcon icon={chatbox} />
          </StyledLabel>
        </LabelWrapper>
      )}

      <ImageWrapper>
        <StyledAvatar
          avatarSrc={userMapped.profilePicture}
          hoverText={userMapped.name}
        />
        <StyledImage
          onClick={(e) => {
            e.preventDefault();
            showModal('IMAGE_MODAL', {
              hasClose: true,
              clickOutsideClose: true,
              children: <ZoomImageContainer src={backgroundImage} />,
            });
          }}
          src={backgroundImage}
        />
        {video && (
          <StyledVideo
            onClick={(e) => e.preventDefault()}
            src={video}
            width="120px"
            height="120px"
            controls
          />
        )}
      </ImageWrapper>
      <ContentWrapper>
        <StyledIonCardHeader>
          <TitleWithLineClamp>{title}</TitleWithLineClamp>
          <ChildrenWrapper>
            {cardState === 'active' && device?.isDesktop && (
              <DropDown icon={ellipsisVertical}>
                {!ideaId && (
                  <>
                    {allCompetitionData &&
                      allCompetitionData.host &&
                      isHost({
                        hostId: allCompetitionData.host.id,
                        authId: currentUser.uid,
                      }) && (
                        <>
                          <Button fill="clear" onClick={startEditing}>
                            <IonIcon slot="icon-only" icon={pencil} />
                          </Button>
                          <Button
                            onClick={() => openInviteModal()}
                            fill="clear"
                          >
                            <IonIcon slot="icon-only" icon={personAdd} />
                          </Button>
                          <Button
                            onClick={() => deleteObjectConfirm('competition')}
                            fill="clear"
                          >
                            <IonIcon slot="icon-only" icon={trashBin} />
                          </Button>
                        </>
                      )}
                    {allCompetitionData &&
                      allCompetitionData.host &&
                      !isHost({
                        hostId: allCompetitionData.host.id,
                        authId: currentUser.uid,
                      }) && (
                        <Button fill="clear" onClick={leaveCompetitionConfirm}>
                          <IonIcon slot="icon-only" icon={personRemove} />
                        </Button>
                      )}
                  </>
                )}
                {allIdeaData &&
                  allIdeaData.author &&
                  isAuthor({
                    authorId: allIdeaData.author.id,
                    authId: currentUser.uid,
                  }) && (
                    <>
                      <Button fill="clear" onClick={startEditing}>
                        <IonIcon slot="icon-only" icon={pencil} />
                      </Button>
                      <Button onClick={deleteObjectConfirm} fill="clear">
                        <IonIcon slot="icon-only" icon={trashBin} />
                      </Button>
                    </>
                  )}
              </DropDown>
            )}
          </ChildrenWrapper>
        </StyledIonCardHeader>
        <StyledIonCardContent>
          <Description>{description}</Description>
        </StyledIonCardContent>
      </ContentWrapper>
    </StyledIonCard>
  );
};

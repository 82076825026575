import styled from 'styled-components';
import { forwardRef } from 'react';
import { ImageType } from '../types/image';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Img = styled(LazyLoadImage)<any>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
export const Image = forwardRef(
  ({ src, height, width, className, ...rest }: ImageType, ref) => {
    return (
      <Img
        src={src}
        height={height}
        width={width}
        className={className}
        {...rest}
      />
    );
  }
);

import styled from 'styled-components';
import { AnchorType } from '../types/anchor';

const ATag = styled.a`
  text-decoration: underline;
  color: inherit;
  pointer-events: inherit;
`;

export const Anchor = ({
  text,
  onClick = () => '',
  href = '#',
  className,
}: AnchorType) => {
  const handleClick = (e) => {
    if (href === '#') {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <ATag href={href} onClick={handleClick} className={className}>
      {text}
    </ATag>
  );
};

import { IonCard } from '@ionic/react';
import styled from 'styled-components';
import { useEffect } from 'react';
import css from '@styled-system/css';
import { useTranslation } from 'react-i18next';

const StyledIonCard = styled(IonCard)`
  max-width: 350px;
  width: 290px;

  margin: auto;
  height: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-style: dotted;

  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  ${css({
    maxWidth: ['290px', '350px', '400px', '320px', '400px'],
    flex: ['0 0 100%', '0 0 100%', '0 0 100%', '1 0 25%', '10%'],
    width: ['290px', '350px', '400px', '320px', '320px'],
  })}
`;

const ContentWrapper = styled.div`
  position: relative;
  justify-content: space-around;
  display: flex;
  height: 420px;
  align-items: center;
  flex-direction: column;
`;

export const TransparentCardIdea = () => {
  const { t } = useTranslation();
  useEffect(() => {}, []);
  return (
    <StyledIonCard>
      <ContentWrapper>
        <p>{t('clickToMakeAnIdea')}</p>
      </ContentWrapper>
    </StyledIonCard>
  );
};

import { auth, nativeAuth } from '../config/firebase';
import { isNative } from './isNative';

const getToken = async () =>
  isNative()
    ? await nativeAuth.getIdToken(true)
    : await auth?.currentUser?.getIdToken();

export const postData = async (url = '', data?) => {
  const token = await getToken();
  const customHeader = setHeaders(token, data);

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: setHeaders(token, data),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: customHeader['Content-Type'] ? JSON.stringify(data) : data,
  });
  if (response.ok) {
    return await response.json();
  }
};

export const putData = async (url = '', data?) => {
  const token = await getToken();
  const customHeader = setHeaders(token, data);
  const response = await fetch(url, {
    method: 'PUT', // Consider PATCH??
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: setHeaders(token, data),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: customHeader['Content-Type'] ? JSON.stringify(data) : data,
  });
  if (response.ok) {
    return await response.json();
  }
};
export const deleteData = async (url = '') => {
  const token = await getToken();

  const response = await fetch(url, {
    method: 'DELETE',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: setHeaders(token),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });
  if (response.ok) {
    return await response.json();
  }
};

export const getData = async (url = '') => {
  const token = await getToken();

  const response = await fetch(url, {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: setHeaders(token),
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  });

  if (response.ok) {
    return await response.json();
  }
};

const setHeaders = (token, data?) => {
  const requiredOptions = {
    Accept: '*/*',
    authorization: token,
  };
  if (data && data instanceof FormData) {
    return requiredOptions;
  } else {
    const headers = {
      ...requiredOptions,
      'Content-Type': 'application/json',
    };
    return headers;
  }
};

import { useEffect, useState } from 'react';
import { IdeaModel } from '../models/idea';
import { getIdea } from '../services/ideaService';
import { useChangeContext } from '../context/ChangeDetection';

export const useFetchSingleIdea = (ideaId: string, competitionId: string) => {
  const { change } = useChangeContext();
  const [singleIdea, setSingleIdea] = useState<IdeaModel>();
  useEffect(() => {
    try {
      if (competitionId && ideaId) {
        getIdea({ competitionId, ideaId }).then((res) => {
          const idea = res?.data || null;
          setSingleIdea(idea);
        });
      }

      return () => {};
    } catch (error) {}
  }, [ideaId, competitionId, change]);
  return singleIdea;
};

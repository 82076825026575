import styled from 'styled-components';
import { Avatar } from '../elements/Avatar';
import { TextWrapper } from './TextWrapper';
import { DropDown } from '../elements/DropDown';
import { ellipsisVerticalOutline } from 'ionicons/icons';
import { Button } from '../elements/Button';
import { Headline } from '../elements/Headlines';
import participantModel from '../models/participants';
import { getUserDoc } from '../services/userService';
import { useEffect, useState } from 'react';
import { removeUserFromCompetition } from '../services/competitionService';
import { useAuthContext } from '../context/Auth';
import { useChangeContext } from '../context/ChangeDetection';
import { useGlobalModalContext } from '../context/GlobalModal';
import { hasHost, isHost, isMe } from '../utils/checkRole';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

const FlexBox = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  > *:not(:last-child) {
    margin-right: 10px;
  }
`;

const DropDownContainer = styled.div`
  margin-left: auto;
`;

const Content = styled.div`
  margin-left: 10px;
`;

const ParticipantList = ({
  participants,
  competitionId,
  hostId,
}: {
  participants: Array<participantModel>;
  competitionId: string;
  hostId: string;
}) => {
  const [participantsData, setparticipantsData] = useState<any>([]);
  const { currentUser } = useAuthContext();
  const { setChange } = useChangeContext();
  const { hideModal } = useGlobalModalContext();
  const history = useHistory();
  const { t } = useTranslation();
  const LeaveChallenge = async ({ competitionId, userId }) => {
    await removeUserFromCompetition({
      competitionId,
      userId: userId,
    });
    setChange(new Date());
    hideModal();
    history.replace('/');
    window.location.reload();
  };
  useEffect(() => {
    setparticipantsData([]);
    participants.forEach((id) => {
      getUserDoc(id).then((userdoc) =>
        setparticipantsData((prev) => [...prev, userdoc])
      );
    });
  }, []);

  return (
    <Content>
      <Headline title={t('currentParticipants')} size="h4" />
      {participantsData &&
        participantsData.length > 0 &&
        participantsData.map((participant, index) => {
          return (
            <TextWrapper
              key={`participant-${index}`}
              children={
                <FlexBox>
                  <Avatar
                    avatarSrc={participant.profilePicture}
                    width="50px"
                    height="50px"
                  />
                  <p>
                    {`${participant.firstName} ${participant.lastName}`}
                    {hostId === participant?.uid && t('host')}
                  </p>
                  {(isMe({
                    userId: participant.uid,
                    authId: currentUser?.uid,
                  }) ||
                    isHost({ hostId, authId: currentUser?.uid })) &&
                    !hasHost({ userId: participant.uid, hostId }) && (
                      <DropDownContainer>
                        <DropDown
                          icon={ellipsisVerticalOutline}
                          centerChildren={false}
                          side="start"
                        >
                          {[
                            <Button
                              onClick={() =>
                                LeaveChallenge({
                                  competitionId,
                                  userId: participant.uid,
                                })
                              }
                              text={t('button.leave')}
                            />,
                          ]}
                        </DropDown>
                      </DropDownContainer>
                    )}
                </FlexBox>
              }
            />
          );
        })}
    </Content>
  );
};
export default ParticipantList;

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthContext } from '../context/Auth';
import { useGlobalModalContext } from '../context/GlobalModal';
import {
  FirstNameField,
  LastNameField,
  ProfileImageField,
  PhoneNumberField,
  Submit,
  StyledForm,
  VerifyPhoneNumberField,
  ErrorMessage,
  NavigationContainer,
  TextNavigationButton,
} from '../components/FormComponents';
import {
  PhoneAuthGetPassword,
  PhoneAuthVerifyPassword,
  PhoneAuthCreateUserDoc,
  isNewUser,
} from '../services/authService';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Anchor } from '../elements/Anchor';

declare const window: any;

const FORM_STEPS = {
  GET_CODE: 'GET_CODE',
  VERIFY_CODE: 'VERIFY_CODE',
  NEW_USER: 'NEW_USER',
};
const StyledPhoneForm = styled(StyledForm)`
  .react-tel-input {
    width: fit-content;
  }
`;

const GooglePolicy = styled.div`
  font-size: x-small;
`;
export const PhoneForm = ({ setHeader, setForm }) => {
  const [step, setStep] = useState('GET_CODE');
  const [authUser, setAuthUser] = useState<any>();
  const { setCurrentUser, authToken, authenticated, currentUser } =
    useAuthContext();

  const { hideModal } = useGlobalModalContext();
  const [errorCode, setErrorCode] = useState();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const onSubmit = async (
    { phoneNumber, smsCode, firstName, lastName, profilePicture },
    e
  ) => {
    e.preventDefault();
    try {
      if (FORM_STEPS.GET_CODE === step) {
        await PhoneAuthGetPassword(phoneNumber);
        setStep(FORM_STEPS.VERIFY_CODE);
      }

      if (FORM_STEPS.VERIFY_CODE === step) {
        const confirmationResult = window.confirmationResult;
        const userCredential = await PhoneAuthVerifyPassword(
          confirmationResult,
          smsCode
        );
        if (userCredential) {
          setAuthUser(userCredential.user);
          const userId = userCredential.user.uid;
          const newUser = await isNewUser(userId);

          if (newUser) {
            setStep(FORM_STEPS.NEW_USER);
          } else {
            hideModal();
          }
        }
      }
      if (FORM_STEPS.NEW_USER === step) {
        const user = {
          firstName,
          lastName,
          profilePicture,
          uid: authUser.uid,
        };
        const userDoc = await PhoneAuthCreateUserDoc(user);
        setCurrentUser(userDoc);
        hideModal();
      }
    } catch (error: any) {
      const { code } = error;
      setErrorCode(code);
    }
  };

  useEffect(() => {
    setHeader(t('pleaseLogin'));
    reset();
  }, []);
  return (
    <StyledPhoneForm onSubmit={handleSubmit(onSubmit)}>
      {FORM_STEPS.GET_CODE === step && (
        <>
          <PhoneNumberField
            errors={errors}
            country={'dk'}
            register={register}
            onChange={(number) => setValue('phoneNumber', '+' + number)}
          />
          <Submit disabled={isSubmitting} text={t('button.sendCode')} />
        </>
      )}
      {FORM_STEPS.VERIFY_CODE === step && (
        <>
          <VerifyPhoneNumberField errors={errors} register={register} />
          <Submit disabled={isSubmitting} text={t('button.verificationCode')} />
        </>
      )}
      {FORM_STEPS.NEW_USER === step && (
        <>
          <FirstNameField register={register} errors={errors} />
          <LastNameField register={register} errors={errors} />
          <ProfileImageField register={register} watch={watch} />
          <Submit disabled={isSubmitting} text={t('button.login')} />
        </>
      )}
      <NavigationContainer>
        {t('goBack')} <Anchor text={t('here')} onClick={() => setForm('')} />
      </NavigationContainer>
      {errorCode && <ErrorMessage error={errorCode} />}

      <GooglePolicy>
        This site is protected by reCAPTCHA and the Google
        <a href="https://policies.google.com/privacy"> Privacy Policy </a>
        and
        <a href="https://policies.google.com/terms"> Terms of Service </a>{' '}
        apply.
      </GooglePolicy>
    </StyledPhoneForm>
  );
};

import React, { useState, createContext, useContext } from 'react';
import { AutheticationModal } from '../components/Modals/AuthTypes';
import { Welcome } from '../components/Modals/Welcome';
import { GenericModal } from '../components/Modals/GenericModal';
import { CurrentParticipants } from '../components/Modals/CurrentParticipants';
import { GeneratePin } from '../components/Modals/GeneratePin';
import { JoinChallenge } from '../components/Modals/JoinChallenge';
import { JoinChallengePreview } from '../components/Modals/JoinChallengePreview';
import { Modal } from '../components/Modal';
import { NotificationModal } from '../components/Modals/Notification';

export const MODAL_TYPES = {
  AUTH_MODAL: 'AUTH_MODAL',
  WELCOME_MODAL: 'WELCOME_MODAL',
  SIGNUP_MODAL: 'SIGNUP_MODAL',
  COMPETITION_IDEA_MODAL: 'COMPETITION_IDEA_MODAL',
  CURRENT_PARTICIPANTS: 'CURRENT_PARTICIPANTS',
  GENERATE_PIN_MODAL: 'GENERATE_PIN_MODAL',
  IMAGE_MODAL: 'IMAGE_MODAL',
  JOIN_CHALLENGE_MODAL: 'JOIN_CHALLENGE_MODAL',
  JOIN_CHALLENGE_PREVIEW_MODAL: 'JOIN_CHALLENGE_PREVIEW_MODAL',
  NOTIFICATIONS: 'NOTIFICATIONS',
};

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.AUTH_MODAL]: AutheticationModal,
  [MODAL_TYPES.WELCOME_MODAL]: Welcome,
  [MODAL_TYPES.COMPETITION_IDEA_MODAL]: GenericModal,
  [MODAL_TYPES.CURRENT_PARTICIPANTS]: CurrentParticipants,
  [MODAL_TYPES.GENERATE_PIN_MODAL]: GeneratePin,
  [MODAL_TYPES.IMAGE_MODAL]: Modal,
  [MODAL_TYPES.JOIN_CHALLENGE_MODAL]: JoinChallenge,
  [MODAL_TYPES.JOIN_CHALLENGE_PREVIEW_MODAL]: JoinChallengePreview,
  [MODAL_TYPES.NOTIFICATIONS]: NotificationModal,
};

type GlobalModalContextType = {
  showModal: (modalType: string, modalProps?: any) => void;
  hideModal: () => void;
  store: any;
};

const initalState: GlobalModalContextType = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
};

const GlobalModalContext = createContext(initalState);
export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: React.FC<{}> = ({ children }) => {
  const [store, setStore] = useState<any>();
  const { modalType, modalProps } = store || {};

  const showModal = (modalType: string, modalProps: any = {}) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
    });
  };

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];

    if (!modalType || !ModalComponent) {
      return null;
    }
    return <ModalComponent id="global-modal" {...modalProps} />;
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};

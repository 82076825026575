import {
  ContentSpaceWrapper,
  FlexColumn,
  StyledIonContent,
} from '../elements/genericWrappers';
import { SectionBar } from '../components/SectionBar';
import { BigCard } from '../components/BigCard';
import Paginate from '../components/Paginate';
import { Comment } from '../components/Comment';
import { CommentInput } from '../components/CommentInput';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { getIdea, next, prev } from '../services/ideaService';
import { IdeaModel } from '../models/idea';
import { IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import { createComment, getAllComments } from '../services/commentService';
import { CommentModel } from '../models/comment';
import { MapToFormData } from '../utils/mappers';
import { CompetitionModel } from '../models/competition';
import { getCompetition } from '../services/competitionService';
import { useChangeContext } from '../context/ChangeDetection';
import { BadgeWrapper, UnBadge } from '../elements/unReadBubble';
import { useAuthContext } from '../context/Auth';
import { useAppContext } from '../context/Shared';
import ErrorBoundary from '../components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import css from '@styled-system/css';
import { SpinnerLoading } from '../elements/SpinnerLoading';

const StyledContentSpaceWrapper = styled(ContentSpaceWrapper)`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  ${css({
    padding: ['10px 30px', '10px 30px', '10px 30px', '10px 40px', '10px 40px'],
  })};
`;

const CommentsContainer = styled.div``;

const CommentSectionSpaceWrapper = styled.div`
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
`;

const BackButton = styled(IonIcon)`
  font-size: 30px;
  margin-top: 25px;
  margin: 1%;
  float: right;
  cursor: pointer;
  right: 0;
  margin-left: auto;
`;

const SingleIdeaPage = ({ match, history }) => {
  const competitionId = match.params.challengeId;
  const ideaId = match.params.ideaId;
  const [idea, setIdea] = useState<IdeaModel>();
  const [competition, setCompetition] = useState<CompetitionModel>();
  const [comments, setComments] = useState<CommentModel[]>([]);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [fetchType, setFetchType] = useState<any>(null);
  const { change, setChange } = useChangeContext();
  const { currentUser } = useAuthContext();
  const sharedContext = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (sharedContext.setCurrentRouteParams) {
      sharedContext.setCurrentRouteParams({
        challengeId: competitionId,
        ideaId,
      });
    }
    if (fetchType === 'prev') {
      PreviousIdea();
    } else if (fetchType === 'next') {
      NextIdea();
    } else {
      try {
        getIdea({ competitionId, ideaId }).then(async (res) => {
          const competitonResponse = await getCompetition({ competitionId });

          if (competitonResponse?.data) {
            setCompetition(competitonResponse.data);
          }
          const idea = res?.data || null;

          setIdea(idea);
          await getComments(ideaId);
        });
      } catch (err) {
        history.push('/home');
      }
    }
    setFetchType('');
    return () => {};
  }, [ideaId, competitionId, change]);

  const getComments = async (ideaId) => {
    if (ideaId) {
      const commentResponse = await getAllComments({ competitionId, ideaId });
      if (commentResponse && commentResponse.data) {
        setComments(commentResponse.data);
      }
    }
  };
  const PreviousIdea = async () => {
    const ideaResponse = await prev({
      ideaId: idea?.id || '',
      competitionId: competitionId,
    });
    if (!ideaResponse?.data) {
      setDisablePrev(true);
    } else {
      setDisableNext(false);
      setIdea(ideaResponse.data);
      history.push(`/idea/${competitionId}/${ideaResponse.data.id}`);
      await getComments(ideaResponse.data.id);
    }
  };
  const NextIdea = async () => {
    const ideaResponse = await next({
      ideaId: idea?.id || '',
      competitionId: competitionId,
    });
    if (!ideaResponse?.data) {
      setDisableNext(true);
    } else {
      setDisablePrev(false);
      setIdea(ideaResponse.data);
      history.push(`/idea/${competitionId}/${ideaResponse.data.id}`);
      await getComments(ideaResponse.data.id);
    }
  };
  const submitComment = async (text, file) => {
    const comment: CommentModel = {
      competitionId,
      ideaId: idea?.id || '',
      text: text,
      commentImage: file ? file : undefined,
    };
    const commentForm = MapToFormData(comment);
    await createComment(commentForm);
    setChange(new Date());
  };
  try {
    if (
      (competition &&
        Object.keys(competition).length === 0 &&
        Object.getPrototypeOf(competition) === Object.prototype) ||
      (idea &&
        Object.keys(idea).length === 0 &&
        Object.getPrototypeOf(idea) === Object.prototype)
    ) {
      throw new Error('Empty');
    }

    return (
      <StyledIonContent>
        <Paginate
          disablePrev={disablePrev}
          disableNext={disableNext}
          previous={() => {
            setFetchType('prev');
            setChange(new Date());
          }}
          next={() => {
            setFetchType('next');
            setChange(new Date());
          }}
        ></Paginate>
        {idea ? (
          <StyledContentSpaceWrapper>
            <BackButton onClick={() => history.go(-1)} icon={close} />
            <BigCard
              title={idea.title}
              description={idea.description}
              backgroundImage={idea.ideaImage}
              video={idea.ideaVideo}
              cardType={'idea'}
              cardState={competition?.ended ? 'inactive' : 'active'}
              cardColor={'blue'}
              ideaId={idea.id}
              lovedBy={idea.lovedBy}
              commentCount={idea.commentCount}
              competitionId={competitionId}
              user={{
                email: undefined,
                name: idea.author?.name || '',
                profilePicture: idea.author?.profilePicture,
              }}
            />
          </StyledContentSpaceWrapper>
        ) : (
          <SpinnerLoading />
        )}
        <CommentSectionSpaceWrapper>
          {!!comments?.length && (
            <SectionBar type={'blue'} title={t('comments')} />
          )}
          {!!comments?.length && (
            <CommentsContainer>
              <FlexColumn>
                {comments.map((comment, index) => (
                  <BadgeWrapper key={'comment-' + index}>
                    <UnBadge
                      unReadBy={comment.unReadBy}
                      authId={currentUser?.uid}
                    />
                    <Comment
                      key={index}
                      avatarSrc={comment.profilePicture}
                      commentText={comment.text}
                      commentImage={comment?.commentImage}
                      authorId={comment.author}
                      commentId={comment.id}
                      competitionId={competitionId}
                      ideaId={ideaId}
                      authorName={`${comment.firstName} ${comment.lastName}`}
                    />
                  </BadgeWrapper>
                ))}
              </FlexColumn>
            </CommentsContainer>
          )}
          {competition?.ended === false && (
            <CommentInput
              submitText={t('button.send')}
              submitEvent={submitComment}
            />
          )}
        </CommentSectionSpaceWrapper>
      </StyledIonContent>
    );
  } catch (error) {
    return <ErrorBoundary error={error} />;
  }
};
export default SingleIdeaPage;

import { auth, nativeAuth } from '../config/firebase';
import { createUserDoc, getUserDoc } from '../services/userService';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  ConfirmationResult,
  FacebookAuthProvider,
  signInWithRedirect,
  UserMetadata,
  RecaptchaVerifier,
  signOut,
  sendPasswordResetEmail,
  User,
} from 'firebase/auth';
import { UserModel } from '../models/user';
import { FacebookLogin as FacebookLoginCap } from '@capacitor-community/facebook-login';
import console from '../utils/log';
import { isNative } from '../utils/isNative';

declare const window: any;

type SignUpType = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  profilePicture?: any;
};

export const ResetPassword = async (email) => {
  if (isNative()) {
    const res = await nativeAuth.sendPasswordResetEmail(email);
    return res;
  }
  const res = await sendPasswordResetEmail(auth, email);
  return res;
};

export const PhoneAuthGetPassword = async (phoneNumber) => {
  const recaptchaVerifier = new RecaptchaVerifier(
    'recaptcha-container',
    {
      size: 'invisible',
      callback: (response) => {
        console.log(response);
      },
      'expired-callback': (e) => {
        console.log(e);
      },
    },
    auth
  );
  try {
    if (isNative()) {
      const confirmationResult = await nativeAuth.verifyPhoneNumber(
        phoneNumber,
        30000
      );

      window.confirmationResult = confirmationResult;
      return;
    }
    const confirmationResult = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      recaptchaVerifier
    );
    window.confirmationResult = confirmationResult;
    return;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const PhoneAuthVerifyPassword = async (
  confirmationResult: ConfirmationResult | any,
  verificationCode
) => {
  try {
    if (isNative()) {
      await nativeAuth.signInWithVerificationId(
        confirmationResult,
        verificationCode
      );

      const nativeUser = await nativeAuth.getCurrentUser();
      const authToken = await nativeAuth.getIdToken(true);
      const user: User = { ...nativeUser, providerId: 'phone' };
      return {
        user,
        authToken,
      };
    } else {
      return await confirmationResult.confirm(verificationCode);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const SignUpWithEmailAndPassword = async (signupForm) => {
  try {
    const { email, password, firstName, lastName, profilePicture } = signupForm;
    let userCredential;
    if (isNative()) {
      await nativeAuth.createUserWithEmailAndPassword(email, password);
      const user = await nativeAuth.getCurrentUser();
      userCredential = {
        user,
      };
    } else {
      userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
    }

    const authUser = userCredential.user;
    const uid = authUser.uid;
    const newUser = {
      email,
      uid,
      firstName,
      lastName,
      profilePicture,
    };
    const userDoc = await createUserDoc(newUser);

    return userDoc;
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};
export const LoginWithEmailAndPassword = async ({ email, password }) => {
  try {
    if (isNative()) {
      return await nativeAuth.signInWithEmailAndPassword(email, password);
    } else {
      return await signInWithEmailAndPassword(auth, email, password);
    }
  } catch (error: any) {
    console.log(error);
    throw error;
  }
};

export const IsNewUser = (authMetadata: UserMetadata) => {
  const createdAt = authMetadata.creationTime;
  const lastLoginAt = authMetadata.lastSignInTime;
  return createdAt === lastLoginAt;
};
export const PhoneAuthCreateUserDoc = async (user) => {
  try {
    const userDoc = await createUserDoc(user);
    return userDoc;
  } catch (error: any) {
    console.log(error);
  }
};
const SignInWithProvider = async (provider) => {
  return await signInWithRedirect(auth, provider);
};
export const HandleAuthWithRedirect = async (authUser, userDoc) => {
  try {
    const uid = authUser.uid;

    const providerData = authUser?.providerData
      ? authUser?.providerData[0]
      : authUser;

    if (providerData) {
      if (!userDoc) {
        const Name = providerData.displayName.split(' ');
        const firstName = Name[0];
        const lastName = Name[1];
        let user: UserModel = {
          firstName: firstName,
          lastName: lastName,
          email: providerData.email,
          provider: providerData.providerId,
          uid: uid,
        };

        if (providerData?.photoURL) {
          user.profilePicture = providerData?.photoURL;
        }
        return await createUserDoc(user);
      }
      return userDoc;
    }
    return userDoc;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const FacebookLogin = async (device?) => {
  try {
    if (isNative()) {
      return await nativeFacebookAuth();
    } else {
      const facebook = new FacebookAuthProvider();
      return await signInWithRedirect(auth, facebook);
    }
  } catch (error) {
    console.log(error);
  }
};
const nativeFacebookAuth = async () => {
  try {
    const FACEBOOK_PERMISSIONS = ['public_profile', 'email'];
    const response = await FacebookLoginCap.login({
      permissions: FACEBOOK_PERMISSIONS,
    });
    if (response.accessToken?.token) {
      // User is signed-in Facebook.
      const token = response.accessToken?.token;
      if (token) {
        return await nativeAuth.signInWithFacebook(token);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const GeninLogin = async ({ email, password }) => {
  const ekuniName = email.substring(0, 8);
  const ekuniMail = ekuniName + '@ekuni.dk';
  try {
    await LoginWithEmailAndPassword({ email: ekuniMail, password });
  } catch (error: any) {
    console.log(error);

    if (
      error.code === 'auth/invalid-email' ||
      error.code === 'auth/user-not-found'
    ) {
      const userName = ekuniName;
      const geninMail = ekuniMail;
      const newUser = {
        firstName: userName,
        lastName: 'genin',
        email: geninMail,
        password: password,
      };

      return await SignUpWithEmailAndPassword(newUser);
    }
  }
};

export const isNewUser = async (userId) => {
  const userDoc = await getUserDoc(userId);
  if (userDoc) {
    return false;
  }
  return true;
};
export const Logout = async () => {
  sessionStorage.clear();
  return isNative() ? await nativeAuth.signOut() : await signOut(auth);
};

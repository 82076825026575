import participantModel from '../../models/participants';
import { Modal } from '../Modal';
import ParticipantList from '../ParticipantsList';
import { useTranslation } from 'react-i18next';

type ParticipantModalType = {
  participants: Array<participantModel>;
  competitionId: string;
  hostId: string;
};

export const CurrentParticipants = ({
  participants,
  competitionId,
  hostId,
}: ParticipantModalType) => {
  const { t } = useTranslation();

  return (
    <Modal header={t('compCanInvite')}>
      <ParticipantList
        competitionId={competitionId}
        hostId={hostId}
        participants={participants}
      />
    </Modal>
  );
};

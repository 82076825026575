import { IonContent } from '@ionic/react';
import styled from 'styled-components';
import css from '@styled-system/css';

export const StyledIonContent = styled(IonContent)`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const ContentSpaceWrapper = styled.div`
  ${css({
    padding: ['0px', '0px', '10px 10px', '10px 10px'],
  })}
`;

export const FlexRow = styled.div`
  display: flex;
  > * {
    margin-right: 5px;
    margin-left: 5px;
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  &:last-child {
    margin-right: 0px;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  > * {
    margin-top: 5px;
    margin-bottom: 5px;
    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

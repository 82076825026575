import { IonSpinner } from '@ionic/react';
import styled from 'styled-components';

const SpinnerContainer = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  ${(props: any) =>
    props.fullpage
      ? `width: 100%;
     height: 100%;
      background-color: white;`
      : `
    width: auto;
    height: auto;
      `}
`;

const StyledIonSpinner = styled(IonSpinner)<any>`
  color: var(--ion-color-primary);
  ${(props: any) =>
    props.small
      ? `
      width: 50px;
    height: 50px;
    `
      : `
    width: 100px;
  height: 100px;
    `}
`;
export const SpinnerLoading = ({ fullpage = true, small = false, ...rest }) => {
  return (
    <SpinnerContainer fullpage={fullpage}>
      <StyledIonSpinner small={small} {...rest} />
    </SpinnerContainer>
  );
};

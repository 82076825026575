import { Redirect, Route } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';

import HomePage from '../pages/HomePage';
import IdeasPage from '../pages/IdeasPage';
import SingleIdeaPage from '../pages/SingleIdeaPage';
import ProfilePage from '../pages/ProfilePage';
import Logout from '../pages/Logout';
import GuardedRoute from './guard';
import Home from '../pages/HomePage';
// Pages
const Main = ({ authenticated, loading }) => {
  return (
    <IonRouterOutlet>
      <Route path="/home" component={HomePage} />
      <GuardedRoute
        loading={loading}
        auth={authenticated}
        exact
        path="/challenge/:challengeId"
        component={IdeasPage}
      />
      <GuardedRoute
        loading={loading}
        auth={authenticated}
        exact
        path="/idea/:challengeId/:ideaId"
        component={SingleIdeaPage}
      />
      <GuardedRoute
        loading={loading}
        auth={authenticated}
        exact
        path="/profile"
        component={ProfilePage}
      />
      <GuardedRoute
        loading={loading}
        auth={authenticated}
        exact
        path="/logout"
        component={Logout}
      />
      <GuardedRoute
        loading={loading}
        auth={authenticated}
        exact
        path="/home/*"
        component={Home}
      />
      <Route render={() => <Redirect to="/home" />} />
    </IonRouterOutlet>
  );
};
export default Main;

import styled from 'styled-components';
import '../theme/variables.scss';

import { LabelType } from '../types/label';
const LabelDiv = styled.div`
  background-color: ${(props) =>
    props.color ? props.color : 'var(--ion-color-primary)'};
  height: 30px;
  width: 100%;
  border-radius: 0px 20px 20px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  overflow: hidden;
`;

const TextP = styled.p`
  display: inline;
  margin-left: 5px;
`;

export const Label = ({
  text,
  color,
  children,
  className,
  ...rest
}: LabelType) => {
  return (
    <LabelDiv className={className} color={color} {...rest}>
      {children}
      <TextP>{text}</TextP>
    </LabelDiv>
  );
};

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const Config = {
  firebase: {
    apiKey: 'AIzaSyAXjE2HgQ3kH-sETQjMyPlEu9RxKqX3qAc',
    authDomain: 'we-love-ideas-live-93ef7.firebaseapp.com',
    databaseURL: 'https://we-love-ideas-live-93ef7.firebaseio.com',
    projectId: 'we-love-ideas-live-93ef7',
    storageBucket: 'we-love-ideas-live-93ef7.appspot.com',
    messagingSenderId: '280898264928',
    appId: '1:280898264928:web:142031b32f6262f3',
    measurementId: 'G-TH3MEXRGCK',
  },

  backend: {
    api: '',
  },
  baseUrl: '',
  bundleId: 'com.weloveideas.live',
};

import styled from 'styled-components';
import { Input } from '../elements/Input';
import { Button } from '../elements/Button';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import CheckIcon from '@mui/icons-material/Check';
import { useRef, useState } from 'react';
import { CommentInputType } from '../types/comment';
import { useTranslation } from 'react-i18next';
import { uploadFile } from '../services/storageService';

const StyledInput = styled(Input)`
  width: 100%;
  line-height: 20px;
`;

const SubmitButton = styled(Button)`
  float: right;
`;

const CheckIconStyled = styled(CheckIcon)`
  margin: 10px;
`;

const StyledCommentInput = styled.div`
  background-color: #e8eaed;
  border-radius: 10px;
  padding: 5px 10px;
  margin-top: 20px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const DefaultIcon = <InsertPhotoIcon />;

export const CommentInput = ({
  submitText,
  children = DefaultIcon,
  submitEvent,
}: CommentInputType) => {
  const [inputText, setInputText] = useState<any>('');
  const [fileObject, setFile] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { t } = useTranslation();
  const onSelectFile = (selectEvent: any) => {
    setFile(selectEvent.target.files[0]);
  };
  const inputChangeEvent = (event: any) => {
    setInputText(event.target.value);
  };
  const fileInput: any = useRef();
  const handleClick = async () => {
    setIsSubmitting(true);
    let file: any = null;
    if (fileObject) {
      file = await uploadFile('comment', fileObject);
    }
    await submitEvent(inputText, file);
    setFile(null);
    setInputText('');
    setIsSubmitting(false);
  };
  return (
    <StyledCommentInput>
      <input
        ref={fileInput}
        hidden
        type="file"
        accept="image/*"
        onChange={onSelectFile}
      />
      <StyledInput
        label={t('writeComment')}
        value={inputText}
        multiline
        id="commentInput"
        onChange={inputChangeEvent}
        rows={4}
      />
      <ButtonWrapper>
        <Button onClick={() => fileInput?.current?.click()}>{children}</Button>
        {fileObject && <CheckIconStyled />}
        <SubmitButton
          disabled={isSubmitting || inputText === '' || fileObject === ''}
          text={submitText}
          onClick={handleClick}
        />
      </ButtonWrapper>
    </StyledCommentInput>
  );
};

import { useEffect, useState } from 'react';
import { debounce } from '../utils/debounce';
import { remToPixel } from '../utils/converter';
import { Capacitor } from '@capacitor/core';

export const useGetDevice = (breakpoints: any) => {
  const delay = 700;

  type deviceType = {
    isMobile: boolean;
    isTablet: boolean;
    isBigTablet: boolean;
    isDesktop: boolean;
    isApp: boolean;
    isNative: string;
  };
  let device: deviceType = {
    isMobile: false,
    isTablet: false,
    isBigTablet: false,
    isDesktop: false,
    isApp: false,
    isNative: 'web',
  };
  const [width = 0, setWidth] = useState<number>();
  const ConvertedBreakpoints = remToPixel(breakpoints);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    handleResize();
    const debounced = debounce(handleResize, delay);

    window.addEventListener('resize', debounced);
    return () => {
      window.removeEventListener('resize', debounced);
    };
  }, [width]);

  if (width < ConvertedBreakpoints[1]) {
    device.isMobile = true;
  }
  if (width > ConvertedBreakpoints[1] && width < ConvertedBreakpoints[2]) {
    device.isTablet = true;
  }
  if (width > ConvertedBreakpoints[2] && width < ConvertedBreakpoints[3]) {
    device.isBigTablet = true;
  }
  if (width > ConvertedBreakpoints[3]) {
    device.isDesktop = true;
  }
  device.isApp = Capacitor.isNativePlatform();
  device.isNative = Capacitor.getPlatform();
  return device;
};

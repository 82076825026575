import {
  EmailField,
  ErrorMessage,
  Submit,
  StyledForm,
} from '../components/FormComponents';
import { useIonToast } from '@ionic/react';
import { ResetPassword } from '../services/authService';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGlobalModalContext } from '../context/GlobalModal';
import { useTranslation } from 'react-i18next';

export const FirebaseResetPasswordForm = ({ setHeader, setForm }) => {
  const [errorCode, setErrorCode] = useState('');
  const [present, dismiss] = useIonToast();
  const { hideModal } = useGlobalModalContext();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      ResetPassword(data.email);
      present({
        position: 'middle',
        color: 'success',
        message: `${t('resetSend')} ${data.email}. ${t('checkMail')}.`,
        duration: 2000,
      });
      hideModal();
    } catch (error: any) {
      const { code } = error;
      setErrorCode(code);
    }
  };
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <EmailField register={register} errors={errors} />
      <Submit text={t('button.send')} />
      {errorCode && <ErrorMessage error={errorCode} />}
    </StyledForm>
  );
};

import { IonIcon } from '@ionic/react';
import { heart } from 'ionicons/icons';
import styled from 'styled-components';
import { useAuthContext } from '../context/Auth';
import { useChangeContext } from '../context/ChangeDetection';
import { Label } from '../elements/Label';
import { loveIdea, unLoveIdea } from '../services/ideaService';

type HeartLabelProps = {
  lovedBy: string[];
  competitionId: string | undefined;
  ideaId: string | undefined;
  canLike: boolean;
  text?: string;
};

const StyledLabel = styled(Label)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

type HasLovedType = {
  hasLoved: boolean;
};

const HeartIcon = styled(IonIcon)<HasLovedType>`
  font-size: x-large;
  color: ${({ hasLoved }) => (hasLoved ? '#d33939' : '')};
`;

export const HeartLabel = ({
  lovedBy,
  competitionId,
  ideaId,
  canLike = false,
  text = '',
}: HeartLabelProps) => {
  const { currentUser } = useAuthContext();
  const { setChange } = useChangeContext();
  const loveToggle = async (e) => {
    e.preventDefault();
    if (!lovedBy.some((id) => id === currentUser?.uid)) {
      await loveIdea({ competitionId, ideaId });
      setChange(new Date());
    } else {
      await unLoveIdea({ competitionId, ideaId });
      setChange(new Date());
    }
  };
  return (
    <>
      <StyledLabel
        text={lovedBy.length + text}
        onClick={(e) => {
          e.preventDefault();
          if (canLike) {
            loveToggle(e);
          }
        }}
      >
        <HeartIcon
          hasLoved={lovedBy.some((id) => id === currentUser?.uid)}
          icon={heart}
        />
      </StyledLabel>
    </>
  );
};

import { createContext, useContext, useState } from 'react';
import { useAuthStateHook } from '../hooks/authState';
import { UserModel } from '../models/user';

type sharedStateType = {
  authToken?: string;
  loading?: boolean;
  authenticated?: boolean;
  setCurrentUser: (currentUser: UserModel) => void;
  currentUser?: UserModel;
  challengePin?: string;
};
const initialState: sharedStateType = {
  authToken: '',
  loading: true,
  authenticated: false,
  setCurrentUser: () => {},
  challengePin: '',
};
const AuthContext = createContext(initialState);

export const useAuthContext = () => useContext(AuthContext);

export function AuthContextProvider({ children }) {
  let { authToken, authUser, loading, authenticated, challengePin } =
    useAuthStateHook();
  const [user, setUser] = useState<any>(null);
  const currentUser = user || authUser;
  const setCurrentUser = (User: UserModel) => {
    setUser({
      ...User,
    });
  };
  const sharedState: sharedStateType = {
    authToken,
    currentUser,
    loading,
    authenticated,
    setCurrentUser,
    challengePin,
  };
  return (
    <AuthContext.Provider value={sharedState}>{children}</AuthContext.Provider>
  );
}

export function AuthContextConsumer({ children }) {
  return <AuthContext.Consumer>{children}</AuthContext.Consumer>;
}

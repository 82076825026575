import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { da, enGB } from 'date-fns/locale';
import { DatePickerType } from '../types/form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledTextField = styled(TextField)`
  margin: 10px 0px;
`;
export const DateTimePicker = ({
  value,
  onInput,
  label = 'Date picker',
}: DatePickerType) => {
  const { i18n } = useTranslation();

  return (
    <LocalizationProvider
      locale={i18n.language === 'da' ? da : enGB}
      dateAdapter={AdapterDateFns}
    >
      <DatePicker
        label={label}
        value={value}
        onChange={(date: any) => {
          onInput(date);
        }}
        renderInput={(params) => <StyledTextField {...params} />}
      />
    </LocalizationProvider>
  );
};

import { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

const GuardedRoute = ({
  component: Component,
  loading,
  auth,
  path,
  ...rest
}) => {
  const [hasBeenAuthenticated, setHasBeenAuthenticated] = useState(false);

  if (path === '/home/*') {
    if (!sessionStorage.getItem('pin')) {
      sessionStorage.setItem('pin', window.location.hash.replace('#', ''));
    }
  } else {
    if (!auth && !hasBeenAuthenticated) {
      sessionStorage.setItem('prev', path);
    }
  }
  if (auth && !hasBeenAuthenticated) {
    setHasBeenAuthenticated(true);
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/home',
              state: {
                referrer:
                  !auth &&
                  (sessionStorage.getItem('prev') ||
                    sessionStorage.getItem('pin'))
                    ? 'auth'
                    : '',
              },
            }}
          />
        )
      }
    />
  );
};

export default GuardedRoute;

import styled from 'styled-components';
import { Headline } from '../elements/Headlines';
import { colors } from '../utils/colorPalette';
import { SectionBarType } from '../types/sectionBar';
import { DropDown } from '../elements/DropDown';
import { addCircleOutline } from 'ionicons/icons';
const Bar = styled.div<any>`
  background-color: ${(props: SectionBarType) =>
    props?.type && colors[props.type]};
  max-width: 100%;
  height: 50px;
  border-radius: 10px;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 1%;
  position: relative;
`;
const ContentWrapper = styled.div`
  margin: 20px 10px;
`;
const StyledHeadline = styled(Headline)`
  margin: 0 auto;
`;

const StyledDropDown = styled(DropDown)`
  position: absolute !important;
  right: 0px;
`;

export const SectionBar = ({
  title,
  className,
  children,
  type = 'green',
  actions,
  authRequired = false,
  authenticated = false,
  alignRight = false,
  disable = false,
}: SectionBarType) => {
  return (
    <ContentWrapper>
      <Bar type={type} className={className}>
        <StyledHeadline title={title} size={'h3'} />
        {!disable && actions && (
          <>
            {authRequired ? (
              <>
                {authenticated && (
                  <DropDown
                    alignRight={alignRight}
                    icon={addCircleOutline}
                    background={false}
                    centerChildren={true}
                  >
                    <>{actions}</>
                  </DropDown>
                )}
              </>
            ) : (
              <StyledDropDown
                icon={addCircleOutline}
                background={false}
                centerChildren={true}
              >
                <>{actions}</>
              </StyledDropDown>
            )}
          </>
        )}
      </Bar>
      {children}
    </ContentWrapper>
  );
};

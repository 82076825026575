import styled from 'styled-components';
import css from '@styled-system/css';
const CardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    ${css({
        justifyContent: ['center', 'center', 'center', 'stretch', 'stretch'],
    })}
`;
export default CardWrapper;

import { CompetitionModel } from '../models/competition';
import { MapToFormData } from '../utils/mappers';
import {
  deleteData,
  getData,
  postData,
  putData,
} from '../utils/requestHandlers';
import console from '../utils/log';
import { MODAL_TYPES } from '../context/GlobalModal';
const endpoint = process.env.REACT_APP_API;
const route = '/competitions';
const api = endpoint + route;

export const getCompetition = async ({
  competitionId,
}: {
  competitionId: string | undefined;
}) => {
  try {
    return await getData(`${api}/single/${competitionId}`);
  } catch (error) {
    return null;
  }
};
export const getPrivateChallenges = async () => {
  try {
    return await getData(`${api}/all_private`);
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const getPublicChallenges = async () => {
  try {
    return await getData(`${api}/all_public`);
  } catch (error) {
    console.log(error);
    return [];
  }
};
export const getEndedChallenges = async () => {
  try {
    return await getData(`${api}/all_ended`);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const createCompetition = async (competition: CompetitionModel) => {
  try {
    const formdata = MapToFormData(competition);

    return await postData(`${api}/create`, formdata);
  } catch (error) {
    console.log(error);

    return null;
  }
};

export const addUserToCompetition = async ({
  competitionId,
}: {
  competitionId: string;
}) => {
  try {
    return await putData(`${api}/add_user`, { competitionId });
  } catch (error) {
    console.log(error);
  }
};
export const removeUserFromCompetition = async ({
  userId,
  competitionId,
}: {
  userId: string | undefined;
  competitionId: string | undefined;
}) => {
  try {
    await deleteData(`${api}/remove_user/${competitionId}/${userId}`);
  } catch (error) {
    console.log(error);
  }
};
export const getCompetitionViaPinCode = async ({
  competitionPin,
}: {
  competitionPin: string;
}) => {
  try {
    return await getData(`${api}/via_pin/${competitionPin}`);
  } catch (error) {
    console.log(error);
  }
};

export const updateCompetition = async (
  competition: Pick<
    CompetitionModel,
    | 'backgroundImage'
    | 'endDate'
    | 'competitionVideo'
    | 'title'
    | 'description'
    | 'id'
  >
) => {
  try {
    const formdata = MapToFormData(competition);
    return await putData(`${api}/update`, formdata);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updateCompetitionPin = async ({
  competitionId,
}: {
  competitionId: string;
}) => {
  try {
    return putData(`${api}/updatePin/${competitionId}`);
  } catch (error) {
    console.log(error);
  }
};

const joinChallengeDB = async (pin: string) => {
  try {
    const res = await getCompetitionViaPinCode({ competitionPin: pin });
    const competition = res.data;
    const competitionId = competition.id;
    await addUserToCompetition({ competitionId });
    return competition;
  } catch (error) {
    console.log(error);
  }
};

export const deleteCompetition = async ({
  competitionId,
}: {
  competitionId?: string;
}) => {
  try {
    await deleteData(`${api}/delete/${competitionId}`);
  } catch (error) {
    console.log(error);
  }
};

export const joinChallenge = async ({
  pin,
  authenticated,
  showModal,
  toast,
  translation,
}) => {
  if (authenticated) {
    if (pin.length === 0) {
      toast({
        message: translation('toastMessages.invalidPin'),
        duration: 2000,
        position: 'bottom',
        color: 'warning',
      });
      return;
    }

    const competitionDoc = await joinChallengeDB(pin);

    if (!competitionDoc?.id) {
      toast({
        message: translation('toastMessages.invalidPin'),
        duration: 2000,
        position: 'bottom',
        color: 'warning',
      });
      return;
    } else {
      showModal(MODAL_TYPES.JOIN_CHALLENGE_PREVIEW_MODAL, {
        challenge: competitionDoc,
      });
    }
  } else {
    showModal(MODAL_TYPES.AUTH_MODAL);
  }
};

import { useEffect, useState } from 'react';
import { auth, nativeAuth } from '../config/firebase';
import { getUserDoc } from '../services/userService';
import { HandleAuthWithRedirect } from '../services/authService';
import { isNative } from '../utils/isNative';
import { useChangeContext } from '../context/ChangeDetection';
import { useTranslation } from 'react-i18next';

const allowedProviders = ['facebook.com', 'Firebase'];

export const useAuthStateHook = () => {
  const [authToken, setAuthToken] = useState<any>(null);
  const [authUser, setAuthUser] = useState<any>(null);
  const [loading, setLoading] = useState<any>(null);
  const [authenticated, setAuthenticated] = useState<any>(null);
  const [challengePin, setChallengePin] = useState<any>(null);
  const { change } = useChangeContext();
  const { i18n } = useTranslation();

  const getAuthDataWeb = () => {
    setLoading(true);
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userId = user.uid;
        const providerId = user.providerData[0].providerId;
        const accessToken = await user.getIdToken();
        setAuthToken(accessToken);
        setAuthenticated(true);
        const userDoc = await getUserDoc(userId);
        if (userDoc?.defaultLanguage) {
          i18n.changeLanguage(userDoc?.defaultLanguage === 'dk' ? 'da' : 'en');
        }
        const pin = sessionStorage.getItem('pin');
        sessionStorage.removeItem('pin');
        if (userDoc) {
          pin && setChallengePin(pin);
          setAuthUser(userDoc);
        }
        if (allowedProviders.includes(providerId)) {
          const providerData = await HandleAuthWithRedirect(user, userDoc);
          if (providerData) {
            const userDoc = providerData;
            pin && setChallengePin(pin);
            setAuthUser(userDoc);
          } else {
            setAuthUser(false);
            setAuthToken(false);
            setAuthenticated(false);
          }
        }
      } else {
        setAuthUser(false);
        setAuthToken(false);
        setAuthenticated(false);
      }
      setLoading(false);
    });
  };
  const getAuthDataNative = async () => {
    setLoading(true);
    nativeAuth.onAuthStateChanged().subscribe(async (user) => {
      setLoading(true);

      if (user) {
        const userId = user.uid;
        const providerId = user.providerId;
        const accessToken = await nativeAuth.getIdToken(true);
        setAuthToken(accessToken);
        setAuthenticated(true);
        const userDoc = await getUserDoc(userId);
        if (userDoc?.defaultLanguage) {
          i18n.changeLanguage(userDoc?.defaultLanguage === 'dk' ? 'da' : 'en');
        }
        const pin = sessionStorage.getItem('pin');
        sessionStorage.removeItem('pin');
        if (userDoc) {
          pin && setChallengePin(pin);
          setAuthUser(userDoc);
        }
        if (allowedProviders.includes(providerId)) {
          const providerData = await HandleAuthWithRedirect(user, userDoc);

          if (providerData) {
            const userDoc = providerData;

            pin && setChallengePin(pin);
            setAuthUser(userDoc);
          } else {
            setAuthUser(false);
            setAuthToken(false);
            setAuthenticated(false);
          }
        }
      } else {
        setAuthUser(false);
        setAuthToken(false);
        setAuthenticated(false);
        setLoading(false);
      }
      setLoading(false);
    });
  };
  useEffect(() => {
    try {
      if (isNative()) {
        getAuthDataNative();
      } else {
        getAuthDataWeb();
      }
    } catch (error) {
      setAuthUser(false);
      setAuthToken(false);
      setAuthenticated(false);
    }
  }, [change]);
  return { authToken, authUser, loading, authenticated, challengePin };
};

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import './theme/variables.scss';
import { IonApp, IonContent, IonRouterLink } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import NavigationBar from './components/NavigationBar';
import { Avatar } from './elements/Avatar';
import { Notification } from './components/Notification';
import Main from './routers/main';
import { AuthContextProvider, AuthContextConsumer } from './context/Auth';
import { AppContextProvider } from './context/Shared';

import { ThemeProvider } from 'styled-components';
import { customThemeWithBreakPoints } from './config/styles';
import { GlobalModal } from './context/GlobalModal';
import { ChangeContextProvider } from './context/ChangeDetection';
import { ListComponent } from './components/NotificationDropDown';
import { LoginButton } from './components/Loginbutton';
import { useState } from 'react';
import { TabBar } from './components/TabBar';
import { usePushNotifications } from './services/FCM';
import { SpinnerLoading } from '../src/elements/SpinnerLoading';

const App: React.FC = () => {
  const [openNotifications, setOpenNotifications] = useState(false);
  usePushNotifications();

  return (
    <IonApp>
      <ThemeProvider theme={customThemeWithBreakPoints}>
        <IonReactRouter>
          <AppContextProvider>
            <AuthContextProvider>
              <AuthContextConsumer>
                {({ currentUser, authenticated, loading }) => (
                  <ChangeContextProvider>
                    <GlobalModal>
                      {!loading ? (
                        <>
                          <NavigationBar>
                            {authenticated && currentUser ? (
                              <>
                                <IonRouterLink href={'/profile'}>
                                  <Avatar
                                    avatarSrc={currentUser?.profilePicture}
                                  />
                                </IonRouterLink>
                                <Notification
                                  onClick={() =>
                                    setOpenNotifications(!openNotifications)
                                  }
                                  currentUser={currentUser}
                                />
                              </>
                            ) : (
                              <LoginButton />
                            )}
                          </NavigationBar>

                          <IonContent>
                            {openNotifications && (
                              <ListComponent
                                closeNotificationList={() =>
                                  setOpenNotifications(false)
                                }
                              />
                            )}
                            <Main
                              authenticated={authenticated}
                              loading={loading}
                            />
                          </IonContent>
                          <TabBar className="TabBar" />
                        </>
                      ) : (
                        <SpinnerLoading name="dots" />
                      )}
                    </GlobalModal>
                  </ChangeContextProvider>
                )}
              </AuthContextConsumer>
            </AuthContextProvider>
          </AppContextProvider>
        </IonReactRouter>
      </ThemeProvider>
    </IonApp>
  );
};

export default App;

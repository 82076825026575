import { Ref, useRef } from 'react';
import styled from 'styled-components';
import css from '@styled-system/css';
import { IonFab, IonFabButton, IonIcon, IonFabList } from '@ionic/react';
import { settings as settingsIcon } from 'ionicons/icons';

const FabList = styled(IonFabList)<any>`
  > ion-button .btnText {
    font-size: 20px;
  }
  ${(props) =>
    props.side === 'top' &&
    `
    margin-bottom: 30px;
  `}
  ${(props) =>
    props.side === 'bottom' &&
    `
    margin-top: 35px;
    margin-left: -14px;
  `}
  ${(props) =>
    props.side === 'start' &&
    `
    margin-right: 10px;
  `}
  ${(props) =>
    props.side === 'end' &&
    `
    margin-left: 10px;
  `}
  align-items: inherit;
`;
const StyledIonFabButton = styled<any>(IonFabButton)`
  margin: 0px;
  ${(props) =>
    props.tab &&
    `
      height: 30px;
      width: 30px;
    `}
  ${(props) =>
    !props.background &&
    css({
      '--boxShadow': 'none',
      '--background': 'transparent',
    })}
`;

const IonFabButtonIcon = styled<any>(IonIcon)`
  ${(props) =>
    props.tab &&
    `
      font-size: 30px;
    `}
`;
const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

type dropDownProps = {
  icon?: string;
  children: JSX.Element | JSX.Element[];
  centerChildren?: Boolean;
  size?: string;
  background?: boolean;
  alignRight?: Boolean;
  side?: 'end' | 'start' | 'bottom' | 'top' | undefined;
  tab?: boolean;
  ref?: any;
  className?: string;
};

const DropDownFab = styled(IonFab)`
  position: relative;
`;

const defaultIcon = settingsIcon;

export const DropDown = ({
  icon = defaultIcon,
  children,
  centerChildren = false,
  background = false,
  alignRight = false,
  side = 'bottom',
  tab = false,
  className = '',
}: dropDownProps) => {
  const fabToggleBtnRef: any = useRef(null);
  const fabListRef: any = useRef(null);

  const expandToggle = async () => {
    if (centerChildren) {
      fabListRef.current.style.visibility = 'hidden'; // hide while calculating offset
      await sleep(50); // wait for dom to render list component
      if (
        fabToggleBtnRef &&
        fabToggleBtnRef.current &&
        fabToggleBtnRef.current.offsetWidth &&
        !isNaN(fabToggleBtnRef.current.offsetWidth)
      ) {
        const halfOfToggleBtn = fabToggleBtnRef.current.offsetWidth / 2;
        const halfOfList = fabListRef.current.offsetWidth / 2;
        fabListRef.current.style.left = `${-halfOfList + halfOfToggleBtn}px`;
        fabListRef.current.style.visibility = 'visible';
      }
    }
    if (alignRight) {
      fabListRef.current.style.visibility = 'hidden'; // hide while calculating offset
      await sleep(50); // wait for dom to render list component
      const listOffsetByToggleBtnEnd =
        fabListRef.current.offsetWidth -
        fabToggleBtnRef.current.offsetWidth -
        13; // 13 is the margin of the list
      fabListRef.current.style.left = `-${listOffsetByToggleBtnEnd}px`;
      fabListRef.current.style.visibility = 'visible';
    }
  };
  return (
    <DropDownFab className={className} onClick={expandToggle}>
      <StyledIonFabButton
        tab={tab}
        background={background}
        size={!tab ? 'small' : 'xx-large'}
        ref={fabToggleBtnRef}
      >
        {<IonFabButtonIcon tab={tab} icon={icon} />}
      </StyledIonFabButton>
      <FabList className="fablist" side={side} ref={fabListRef}>
        {children}
      </FabList>
    </DropDownFab>
  );
};

import { useEffect, useState } from 'react';
import { useChangeContext } from '../context/ChangeDetection';
import { IdeaModel } from '../models/idea';
import { getAllIdeas } from '../services/ideaService';

export const useFetchIdeas = (competitionId: string) => {
  const [ideas, setIdeas] = useState<IdeaModel[]>([]);
  const { change } = useChangeContext();

  useEffect(() => {
    try {
      getAllIdeas({ competitionId }).then((res) => {
        const ideas = Array.isArray(res.data) ? res.data : [];
        setIdeas(ideas);
      });
    } catch (error) {
      setIdeas([]);
    }
  }, [competitionId, change]);
  return { ideas };
};

const log = (msg, value?) => {
  const env = process.env.REACT_APP_ENV;
  if (env !== 'production') {
    console.log(msg, value);
  }
};
const Console = {
  log,
};
export default Console;

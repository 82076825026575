import { Dialog } from '@mui/material';
import { Button } from '../elements/Button';
import { IonIcon, IonModal } from '@ionic/react';
import { close as closeIcon } from 'ionicons/icons';
import styled from 'styled-components';
import { Headline } from '../elements/Headlines';
import { useGlobalModalContext } from '../context/GlobalModal';
import { useAppContext } from '../context/Shared';
import { useEffect, useState } from 'react';
import css from '@styled-system/css';

type ModalType = {
  children: JSX.Element;
  className?: string;
  open?: boolean;
  close?: () => void;
  hasClose?: boolean;
  header?: string;
  headerStyle?: 'large' | 'normal';
  fullScreen?: boolean;
  fullWidth?: boolean;
  clickOutsideClose?: boolean;
};

const StyledDialog = styled(Dialog)`
  & > .MuiDialog-container > .MuiPaper-root {
    overflow-y: auto !important;
    height: fit-content;
    ${css({
      width: ['100%', '100%', '70%', '50%'],
    })};
  }
`;

const StyledIonModal = styled(IonModal)`
  /*   overflow-y: scroll !important;
  height: fit-content;
  ${css({
    width: ['100%', '100%', '70%', '50%'],
  })}; */
`;
const CancelButton = styled(Button)<any>`
  ${(props) =>
    props.isApp &&
    `
    margin-top: 50px;
  `}
  :hover {
    opacity: 0.3;
  }
  margin-left: auto;
`;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  align-items: end;
`;
const Container = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
`;
const HeaderTitle = styled(Headline)<Pick<ModalType, 'headerStyle'>>`
  font-weight: bold;
  margin: auto;
  ${({ headerStyle }) =>
    headerStyle === 'large'
      ? `
            text-align: center;
            margin: auto;
            padding: 0px 15px;
            font-size: 40px;
        `
      : `
            text-align: center;
      `};
`;

export const Modal = ({
  className,
  children,
  header = '',
  headerStyle = 'normal',
  hasClose = true,
  clickOutsideClose = false,
  fullScreen = false,
  fullWidth = false,
}: ModalType) => {
  const { hideModal } = useGlobalModalContext();
  const [showModal, setShowModal] = useState(true);
  const { device } = useAppContext();
  const Modal: any = !device?.isMobile ? StyledDialog : StyledIonModal;

  useEffect(() => {
    if (!showModal) {
      hideModal();
    }
  }, [showModal]);
  const props = device?.isMobile
    ? {
        swipeToClose: true,
        backdropDismiss: true,
        onDidDismiss: () => {
          setShowModal(false);
        },
        isOpen: showModal,
      }
    : {
        fullScreen: fullScreen,
        fullWidth: fullWidth,
        className: className,
        open: true,
        onClose: () => (clickOutsideClose ? hideModal() : null),
      };

  return (
    <Modal {...props}>
      <HeaderContainer>
        {hasClose && (
          <CancelButton
            isApp={device?.isApp}
            onClick={hideModal}
            header={!!header}
            size={'small'}
            fill="clear"
          >
            <IonIcon slot="end" icon={closeIcon} />
          </CancelButton>
        )}
        {header && (
          <HeaderTitle headerStyle={headerStyle} title={header} size={'h3'} />
        )}
      </HeaderContainer>
      <Container>{children}</Container>
    </Modal>
  );
};

import { SectionBar } from '../components/SectionBar';
import { Card } from '../components/Card';
import CardWrapper from '../elements/CardWrapper';
import {
  ContentSpaceWrapper,
  StyledIonContent,
} from '../elements/genericWrappers';
import { IonRouterLink, useIonToast } from '@ionic/react';
import { CompetitionModel } from '../models/competition';
import { BadgeWrapper, UnBadge } from '../elements/unReadBubble';

import { useAuthContext } from '../context/Auth';
import { MODAL_TYPES, useGlobalModalContext } from '../context/GlobalModal';
import {
  useFetchEndedChallenges,
  useFetchPrivateChallenges,
  useFetchPublicChallenges,
} from '../hooks/challenges';

import { useEffect } from 'react';
import { joinChallenge } from '../services/competitionService';
import { useHistory } from 'react-router';
import { TransparentCard } from '../components/TransparentCard';
import ErrorBoundary from '../components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../context/Shared';
import { SpinnerLoading } from '../elements/SpinnerLoading';

const Home = ({ location }) => {
  const history = useHistory();
  const { showModal } = useGlobalModalContext();
  const { currentUser, authenticated, challengePin, loading } =
    useAuthContext();
  const [present] = useIonToast();
  const { t } = useTranslation();
  const { device } = useAppContext();
  const privateChallenges: CompetitionModel[] =
    useFetchPrivateChallenges(currentUser);
  const endedChallenges: CompetitionModel[] =
    useFetchEndedChallenges(currentUser);
  const publicChallenges: CompetitionModel[] =
    useFetchPublicChallenges(currentUser);

  useEffect(() => {
    if (authenticated === false) {
      if (location?.state?.referrer === 'auth') {
        showModal(MODAL_TYPES.AUTH_MODAL, { headerStyle: 'large' });
      } else if (window.location.hash) {
        const challengePinHash = window.location.hash.replace('#', '');
        sessionStorage.setItem('pin', challengePinHash);
        showModal(MODAL_TYPES.AUTH_MODAL, { headerStyle: 'large' });
      } else {
        //showModal(MODAL_TYPES.WELCOME_MODAL, { join });
      }
    } else if (challengePin && authenticated) {
      joinChallenge({
        authenticated,
        translation: t,
        pin: challengePin,
        showModal,
        toast: present,
      });
    } else if (authenticated && window.location.hash) {
      const challengePinHash = window.location.hash.replace('#', '');
      joinChallenge({
        authenticated,
        translation: t,
        pin: challengePinHash,
        showModal,
        toast: present,
      });
    } else if (sessionStorage.getItem('prev')) {
      const prev = sessionStorage.getItem('prev');
      sessionStorage.removeItem('prev');
      prev && history.push(prev);
    }
  }, [authenticated, challengePin]);

  try {
    return (
      <StyledIonContent>
        <ContentSpaceWrapper>
          <SectionBar
            alignRight={true}
            authRequired={false}
            authenticated={authenticated}
            type={'green'}
            title={t('competitionPage.myCompetitions')}
            disable={!device?.isDesktop}
          >
            <CardWrapper>
              {device?.isDesktop && (
                <IonRouterLink>
                  <TransparentCard
                    authenticated={authenticated}
                    joinChallenge={() =>
                      joinChallenge({
                        authenticated,
                        translation: t,
                        pin: challengePin,
                        showModal,
                        toast: present,
                      })
                    }
                  />
                </IonRouterLink>
              )}

              {!loading && privateChallenges ? (
                privateChallenges.map((challenge, index) => {
                  return (
                    <IonRouterLink
                      key={'publicChallenge-' + index}
                      href={`/challenge/${challenge.id}`}
                    >
                      <BadgeWrapper>
                        <UnBadge
                          unReadBy={challenge.unReadBy}
                          authId={currentUser?.uid}
                        />

                        <Card
                          competitionPin={challenge.PIN}
                          competitionId={challenge.id}
                          title={challenge.title}
                          description={challenge.description}
                          backgroundImage={challenge.backgroundImage}
                          user={challenge.host}
                          participants={challenge.participants}
                          ideaCount={challenge.ideaCount}
                          video={challenge.competitionVideo}
                          daysLeft={challenge.daysLeft}
                          cardColor={'green'}
                        />
                      </BadgeWrapper>
                    </IonRouterLink>
                  );
                })
              ) : (
                <SpinnerLoading small={true} fullpage={false} />
              )}
            </CardWrapper>
          </SectionBar>
        </ContentSpaceWrapper>
        <ContentSpaceWrapper>
          <SectionBar
            type={'gray'}
            title={t('competitionPage.publicCompetitions')}
          >
            <CardWrapper>
              {!loading && publicChallenges ? (
                publicChallenges.map((challenge, index) => {
                  return (
                    <IonRouterLink
                      key={'publicChallenges-' + index}
                      href={`/challenge/${challenge.id}`}
                    >
                      <BadgeWrapper>
                        <UnBadge
                          unReadBy={challenge.unReadBy}
                          authId={currentUser?.uid}
                        />
                        <Card
                          competitionPin={challenge.PIN}
                          competitionId={challenge.id}
                          title={challenge.title}
                          description={challenge.description}
                          backgroundImage={challenge.backgroundImage}
                          user={challenge.host}
                          participants={challenge.participants}
                          ideaCount={challenge.ideaCount}
                          video={challenge.competitionVideo}
                          daysLeft={challenge.daysLeft}
                          cardColor={'green'}
                        />
                      </BadgeWrapper>
                    </IonRouterLink>
                  );
                })
              ) : (
                <SpinnerLoading small={true} fullpage={false} />
              )}
            </CardWrapper>
          </SectionBar>
        </ContentSpaceWrapper>
        <ContentSpaceWrapper>
          <SectionBar
            type="gray"
            title={t('competitionPage.earlierCompetitions')}
          >
            <CardWrapper>
              {!loading && endedChallenges ? (
                endedChallenges.map((challenge, index) => {
                  return (
                    <IonRouterLink
                      key={'endedChallenge-' + index}
                      href={`/challenge/${challenge.id}`}
                    >
                      <BadgeWrapper>
                        <UnBadge
                          unReadBy={challenge.unReadBy}
                          authId={currentUser?.uid}
                        />

                        <Card
                          competitionPin={challenge.PIN}
                          competitionId={challenge.id}
                          title={challenge.title}
                          description={challenge.description}
                          backgroundImage={challenge.backgroundImage}
                          user={challenge.host}
                          participants={challenge.participants}
                          ideaCount={challenge.ideaCount}
                          video={challenge.competitionVideo}
                          daysLeft={challenge.daysLeft}
                          winner={challenge.winner}
                          cardColor={challenge.winner ? 'gold' : 'gray'}
                        />
                      </BadgeWrapper>
                    </IonRouterLink>
                  );
                })
              ) : (
                <SpinnerLoading small={true} fullpage={false} />
              )}
            </CardWrapper>
          </SectionBar>
        </ContentSpaceWrapper>
      </StyledIonContent>
    );
  } catch (error) {
    return <ErrorBoundary error={error} />;
  }
};

export default Home;

import { Button } from '../elements/Button';
import { logInOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { useGlobalModalContext, MODAL_TYPES } from '../context/GlobalModal';
export const LoginButton = () => {
  const { showModal } = useGlobalModalContext();
  return (
    <Button
      fill="clear"
      onClick={() =>
        showModal(MODAL_TYPES.AUTH_MODAL, { headerStyle: 'large' })
      }
    >
      <IonIcon size="large" icon={logInOutline} />
    </Button>
  );
};

import { IonButton } from '@ionic/react';
import styled from 'styled-components';
import css from '@styled-system/css';
type buttonProps = {
  text?: string;
  children?: JSX.Element;
  fill?: 'clear' | 'default' | 'outline' | 'solid' | undefined;
  [x: string]: any;
  className?: string;
};

const StyledButton = styled(IonButton)`
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0;
  background: ${(props) => props?.color};
  justify-content: flex-start !important;
  display: flex;
  ${css({
    width: ['fit-content'],
    fontSize: ['small', 'small', 'medium', 'medium'],
  })}
`;
const TextContainer = styled.div`
  ${css({
    fontSize: ['small', 'small', 'medium', 'medium'],
  })}
  text-transform: capitalize;
  text-align: start;
  flex: 5;
`;

const Button = ({ text, className, children, ...rest }: buttonProps) => (
  <StyledButton className={className} {...rest}>
    {children}
    <TextContainer className="btnText"> {text && text}</TextContainer>
  </StyledButton>
);

export { Button };

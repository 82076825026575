const isFile = (input) => 'File' in window && input instanceof FileList;

export const hostAuthorMap = ({ user }) => {
  const newUserModel = {
    name: user?.hostName ? user.hostName : user.name,
    profilePicture: user?.hostProfileImage
      ? user.hostProfileImage
      : user.profilePicture,
    email: user?.hostEmail ? user.hostEmail : user.email,
    userId: user?.hostId ? user.hostId : user?.userId ? user?.userId : user.id,
  };
  return newUserModel;
};

export const mediaMapper = Object.freeze({
  Competition: {
    video: 'competitionVideo',
    image: 'backgroundImage',
  },
  Idea: {
    video: 'ideaVideo',
    image: 'ideaImage',
  },
});
export const MapToFormData = (fData) => {
  const data = new FormData();
  for (const key in fData) {
    if (isFile(fData[key])) {
      fData[key] = fData[key][0];
    }
    if (key === 'field') {
      data.append(key, fData[key][1]);
    } else {
      data.append(key, fData[key]);
    }
    if (data.get(key) === 'undefined') {
      data.delete(key);
    }
  }

  return data;
};

import {
  IonIcon,
  IonLabel,
  IonBadge,
  IonRouterLink,
  useIonToast,
} from '@ionic/react';
import {
  homeOutline,
  notificationsOutline,
  addCircleOutline,
} from 'ionicons/icons';
import styled from 'styled-components';
import { FlexColumn } from '../elements/genericWrappers';
import { DropDown } from '../elements/DropDown';
import { Button } from '../elements/Button';
import { useAuthContext } from '../context/Auth';
import { MODAL_TYPES, useGlobalModalContext } from '../context/GlobalModal';
import { useLocation } from 'react-router-dom';
import { updateUserDoc, getUserDoc } from '../services/userService';
import { useRef } from 'react';
import { useFetchChallenge } from '../hooks/challenges';
import { deleteIdea, loveIdea, unLoveIdea } from '../services/ideaService';
import { IonFooter } from '@ionic/react';
import { useAppContext } from '../context/Shared';
import { useChangeContext } from '../context/ChangeDetection';
import { useFetchSingleIdea } from '../hooks/singleIdea';
import { isAuthor, isHost } from '../utils/checkRole';
import { useHistory } from 'react-router-dom';
import {
  deleteCompetition,
  removeUserFromCompetition,
} from '../services/competitionService';
import { useTranslation } from 'react-i18next';
import { uploadFile } from '../services/storageService';

type TabBarType = {
  className: string;
};

const Tab = styled(FlexColumn)`
  align-items: center;
  margin: 20px !important;
  width: 60px;
`;

const TabsContainer = styled(IonFooter)`
  display: flex;
  flex-direction: row;
  > * {
    margin-top: 5px;
    margin-bottom: 5px;
    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
  justify-content: center;
`;

const TabDropDown = styled(DropDown)`
  width: 20px !important;
  .fablist {
    align-items: center;

    .btnText {
      text-transform: none;
    }
  }
`;

const StyledIcon = styled(IonIcon)`
  width: 30px;
  height: 30px;
`;

const StyledIonBadge = styled(IonBadge)`
  position: absolute;
  margin-top: -10px;
  margin-left: 15px;
`;

export const TabBar = ({ className }: TabBarType) => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const { currentUser, authenticated, setCurrentUser } =
    useAuthContext() as any;
  const location = useLocation();
  const history = useHistory();
  const pathName = location.pathname;
  const sharedContext = useAppContext();
  const changeContext = useChangeContext();
  const params = sharedContext.getRouteParams;
  const [present, dismiss] = useIonToast();
  const isDesktop = sharedContext?.device?.isDesktop;
  const ideaId = params?.ideaId || '';
  const challengeId = params?.challengeId || '';
  const notificationCount = currentUser?.notificationCount || 0;

  const onSelectFile = async (selectEvent: any) => {
    const file = selectEvent.target.files[0];
    const newFileUrl = await uploadFile('profilePicture', file);
    if (newFileUrl) {
      const userDoc = {
        ...currentUser,
        profilePicture: newFileUrl,
      };
      setCurrentUser(userDoc);
    }
  };

  const leaveCompetition = async () => {
    if (removeUserFromCompetition) {
      await removeUserFromCompetition({
        userId: currentUser?.uid,
        competitionId: params?.challengeId,
      });
    }
    history.replace('/');
    window.location.reload();
  };
  const leaveCompetitionConfirm = () => {
    present({
      position: 'middle',
      color: 'warning',
      message: t('wantToLeave'),
      buttons: [
        { text: t('yes'), handler: leaveCompetition },
        { text: t('no'), handler: dismiss },
      ],
    });
  };
  const deleteChallengeConfirm = () => {
    present({
      position: 'middle',
      color: 'warning',
      message: t('wantToDelete'),
      buttons: [
        { text: t('yes'), handler: deleteChallenge },
        { text: t('no'), handler: dismiss },
      ],
    });
  };
  const deleteIdeaConfirm = () => {
    present({
      position: 'middle',
      color: 'warning',
      message: t('wantToDelete'),
      buttons: [
        { text: t('yes'), handler: deleteIdeaTabAction },
        { text: t('no'), handler: dismiss },
      ],
    });
  };
  const deleteChallenge = async () => {
    if (deleteCompetition) {
      await deleteCompetition({
        competitionId: params?.challengeId,
      });
    }
    history.replace('/');
    window.location.reload();
  };
  const deleteIdeaTabAction = async () => {
    if (deleteIdea) {
      await deleteIdea({
        competitionId: params?.challengeId,
        ideaId: params?.ideaId,
      });
    }
    history.go(-1);
  };

  const focusComment = async () => {
    const commentInputRef = document.getElementById('commentInput');
    commentInputRef?.focus();
  };

  const fileInput: any = useRef(null);

  const competition = useFetchChallenge(params?.challengeId || '');
  const idea = useFetchSingleIdea(ideaId, challengeId);

  const openInviteModal = () => {
    showModal(MODAL_TYPES.GENERATE_PIN_MODAL, {
      competitionPin: competition?.PIN,
      participants: competition?.participants,
      competitionId: competition?.id,
      hostId: competition?.host?.id,
    });
  };

  return (
    <>
      {!isDesktop && (
        <TabsContainer className={className}>
          <IonRouterLink href="/home">
            <Tab>
              <StyledIcon className="cancel-click-event" icon={homeOutline} />
            </Tab>
          </IonRouterLink>
          <Tab>
            {authenticated && !competition?.ended ? (
              <>
                {pathName.includes('home') && authenticated && (
                  <TabDropDown
                    tab={true}
                    centerChildren={true}
                    side="top"
                    icon={addCircleOutline}
                  >
                    <Button
                      onClick={() =>
                        showModal('COMPETITION_IDEA_MODAL', {
                          actionType: 'Create',
                          objectType: 'Competition',
                        })
                      }
                      text={t('button.createCompetition')}
                    />
                    <Button
                      onClick={() =>
                        showModal(MODAL_TYPES.JOIN_CHALLENGE_MODAL)
                      }
                      text={t('button.joinCompetition')}
                    />
                  </TabDropDown>
                )}
                {pathName.includes('profile') && authenticated && (
                  <div>
                    <TabDropDown
                      tab={true}
                      centerChildren={true}
                      side="top"
                      icon={addCircleOutline}
                    >
                      <Button
                        onClick={() => fileInput?.current?.click()}
                        text={t('button.changeProfilePic')}
                      />
                    </TabDropDown>
                    <input
                      ref={fileInput}
                      hidden
                      type="file"
                      accept="image/*"
                      onChange={onSelectFile}
                    />
                  </div>
                )}
                {pathName.includes('challenge') && authenticated && (
                  <TabDropDown
                    tab={true}
                    centerChildren={true}
                    side="top"
                    icon={addCircleOutline}
                  >
                    <>
                      {isHost({
                        authId: currentUser?.uid,
                        hostId: competition?.host?.id,
                      }) && (
                        <>
                          <Button
                            onClick={() => deleteChallengeConfirm()}
                            text={t('button.deleteCompetition')}
                          />
                          <Button
                            onClick={() => openInviteModal()}
                            text={t('button.inviteToCompetition')}
                          />
                          <Button
                            onClick={() =>
                              showModal('COMPETITION_IDEA_MODAL', {
                                competition,
                                actionType: 'Edit',
                                objectType: 'Competition',
                              })
                            }
                            text={t('button.editCompetition')}
                          />
                        </>
                      )}
                    </>

                    <>
                      {!isHost({
                        authId: currentUser?.uid,
                        hostId: competition?.host?.id,
                      }) && (
                        <Button
                          onClick={() => leaveCompetitionConfirm()}
                          text={t('button.leaveCompetition')}
                        />
                      )}
                    </>
                    <Button
                      onClick={() =>
                        showModal('COMPETITION_IDEA_MODAL', {
                          competition,
                          actionType: 'Create',
                          objectType: 'Idea',
                        })
                      }
                      text={t('button.createIdea')}
                    />
                  </TabDropDown>
                )}
                {pathName.includes('idea') && authenticated && (
                  <TabDropDown
                    tab={true}
                    centerChildren={true}
                    side="top"
                    icon={addCircleOutline}
                  >
                    <>
                      {!isAuthor({
                        authId: currentUser?.uid,
                        authorId: idea?.author?.id,
                      }) && (
                        <Button
                          onClick={() => {
                            const isLovedBy = idea?.lovedBy?.find(
                              (love) => love === currentUser?.uid
                            );
                            !isLovedBy
                              ? loveIdea({
                                  competitionId: params?.challengeId,
                                  ideaId: params?.ideaId,
                                })
                              : unLoveIdea({
                                  competitionId: params?.challengeId,
                                  ideaId: params?.ideaId,
                                });
                            changeContext.setChange(new Date());
                          }}
                          text={t('button.loveIdea')}
                        />
                      )}
                    </>
                    <>
                      {isAuthor({
                        authId: currentUser?.uid,
                        authorId: idea?.author?.id,
                      }) && (
                        <>
                          <Button
                            onClick={() => {
                              deleteIdeaConfirm();
                            }}
                            text={t('button.deleteIdea')}
                          />
                          <Button
                            onClick={() => {
                              showModal('COMPETITION_IDEA_MODAL', {
                                idea,
                                actionType: 'Edit',
                                objectType: 'Idea',
                              });
                            }}
                            text={t('button.editIdea')}
                          />
                        </>
                      )}
                    </>
                    <Button
                      onClick={() => focusComment()}
                      text={t('button.createComment')}
                    />
                  </TabDropDown>
                )}
              </>
            ) : (
              <TabDropDown
                tab={true}
                centerChildren={true}
                side="top"
                icon={addCircleOutline}
              >
                <Button
                  onClick={() => showModal(MODAL_TYPES.JOIN_CHALLENGE_MODAL)}
                  text={t('button.joinCompetition')}
                />
                <Button
                  onClick={() => showModal(MODAL_TYPES.AUTH_MODAL)}
                  text={t('button.login')}
                />
              </TabDropDown>
            )}
          </Tab>
          <Tab>
            <StyledIcon
              className="cancel-click-event"
              icon={notificationsOutline}
              onClick={() => {
                if (authenticated) {
                  showModal(MODAL_TYPES.NOTIFICATIONS);
                }
              }}
            />
            {notificationCount > 0 && (
              <StyledIonBadge>{notificationCount}</StyledIonBadge>
            )}
          </Tab>
        </TabsContainer>
      )}
    </>
  );
};

import { IonBadge } from '@ionic/react';
import styled from 'styled-components';

const StyledBadge = styled(IonBadge)`
  position: absolute;
  right: -5px;
  top: -5px;
  z-index: 1;
  border-radius: 20px;
  width: 10px;
  height: 10px;
  padding: 0;
`;

const BadgeWrapper = styled.div`
  position: relative;
`;
const UnBadge = ({ unReadBy = [''], authId }) => {
  if (unReadBy.includes(authId)) {
    return <StyledBadge children=" " />;
  }
  return <></>;
};

export { UnBadge, BadgeWrapper };

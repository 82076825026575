import styled from 'styled-components';
import { Avatar } from '../elements/Avatar';
import { CommentType } from '../types/comment';
import { Image } from '../elements/Image';
import { FlexColumn, FlexRow } from '../elements/genericWrappers';
import { useGlobalModalContext, MODAL_TYPES } from '../context/GlobalModal';
import { DropDown } from '../elements/DropDown';
import { Button } from '../elements/Button';
import { IonIcon, useIonToast } from '@ionic/react';
import {
  ellipsisVertical,
  pencil,
  trashBin,
  checkmarkCircleOutline,
} from 'ionicons/icons';
import { MapToFormData } from '../utils/mappers';
import { useChangeContext } from '../context/ChangeDetection';
import { useAuthContext } from '../context/Auth';
import { deleteComment, updateComment } from '../services/commentService';
import { useRef, useState } from 'react';
import { Input } from '../elements/Input';
import { isAuthor } from '../utils/checkRole';
import { useTranslation } from 'react-i18next';
import { uploadFile } from '../services/storageService';

const CommentWrapperDiv = styled(FlexColumn)`
  text-align: center;
  background-color: whitesmoke;
  width: 100%;
  top: 42px;
  height: fit-content;
  padding: 20px;
  border-radius: 10px 10px 10px 10px;
  word-break: break-word;
`;
const CommentEditInput = styled(Input)`
  text-align: left;
  margin-left: 20px;
  flex: 2;
`;
const CommentTextP = styled.div`
  text-align: left;
  margin-left: 20px;
  flex: 2;
`;
const ButtonsDiv = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const StyledImage = styled(Image)`
  flex: 0;
`;

const StyledDropDown = styled(DropDown)`
  flex: 0;
`;

const CenterFlexRow = styled(FlexRow)`
  justify-content: center;
`;
const StyledAvatar = styled(Avatar)`
  aspect-ratio: 1/1;
`;
const ZoomImageContainer = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const Comment = ({
  avatarSrc,
  commentText,
  children,
  commentImage,
  authorId,
  commentId,
  competitionId,
  ideaId,
  authorName,
}: CommentType) => {
  const [present, dismiss] = useIonToast();
  const { currentUser } = useAuthContext();
  const [commentBeingEdited, setCommentBeingEdited] = useState(false);
  const [newCommentText, setNewCommentText] = useState(commentText);
  const [savedImageDeleted, setSavedImageDeleted] = useState(false);
  const { setChange } = useChangeContext();
  const { t } = useTranslation();

  const [fileObject, setFile] = useState(null);
  const onSelectFile = (selectEvent: any) => {
    setFile(selectEvent.target.files[0]);
  };

  const saveComment = async () => {
    if (ideaId && competitionId) {
      let newCommentImage: any = null;
      if (fileObject) {
        newCommentImage = await uploadFile('comment', fileObject);
      }
      const imageToUpload = newCommentImage
        ? newCommentImage
        : savedImageDeleted
        ? ''
        : commentImage;

      const mappedComment = MapToFormData({
        text: newCommentText,
        id: commentId,
        ideaId,
        competitionId,
        commentImage: imageToUpload,
      });
      await updateComment(mappedComment);
      setChange(new Date());
      setCommentBeingEdited(false);
      setNewCommentText(commentText);
      setSavedImageDeleted(false);
      setFile(null);
      fileInput.current.value = null;
    }
  };
  const startEditing = () => {
    setCommentBeingEdited(true);
  };
  const deleteCommentConfirm = () => {
    if (competitionId && ideaId && commentId) {
      present({
        position: 'middle',
        color: 'warning',
        message: t('wantToDeleteComment'),
        buttons: [
          {
            text: t('yes'),
            handler: async () => {
              await deleteComment({ competitionId, commentId, ideaId });
              setChange(new Date());
            },
          },
          { text: t('no'), handler: dismiss },
        ],
      });
    }
  };

  const inputChangeEvent = (event) => {
    setNewCommentText(event.target.value);
  };
  const { showModal } = useGlobalModalContext();
  const unsetImage = () => {
    setSavedImageDeleted(true);
  };
  const fileInput: any = useRef();

  return (
    <div>
      <CommentWrapperDiv>
        <input
          ref={fileInput}
          hidden
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />
        <CenterFlexRow>
          <StyledAvatar
            avatarSrc={avatarSrc}
            hoverText={authorName}
            width={'72px'}
            height={'72px'}
          />
          {!commentBeingEdited && <CommentTextP> {commentText} </CommentTextP>}
          {commentBeingEdited && (
            <CommentEditInput
              multiline={true}
              label={t('comment')}
              onChange={inputChangeEvent}
              defaultValue={commentText}
            />
          )}
          {children}
          {children && <ButtonsDiv children={children} />}
          {isAuthor({ authId: currentUser?.uid, authorId }) &&
            !commentBeingEdited && (
              <StyledDropDown
                side="bottom"
                icon={ellipsisVertical}
                centerChildren={true}
              >
                <>
                  <Button onClick={startEditing}>
                    <IonIcon icon={pencil} />
                  </Button>
                  <Button onClick={deleteCommentConfirm}>
                    <IonIcon icon={trashBin} />
                  </Button>
                </>
              </StyledDropDown>
            )}
        </CenterFlexRow>
        <CenterFlexRow>
          {commentBeingEdited && (
            <>
              <Button text={t('button.save')} onClick={saveComment} />
              {commentImage && !savedImageDeleted && (
                <Button
                  onClick={() => {
                    unsetImage();
                  }}
                  text={t('button.removeImage')}
                />
              )}
              {(!commentImage ||
                (commentImage && savedImageDeleted && !fileObject)) && (
                <Button
                  text={t('button.uploadImage')}
                  onClick={() => fileInput?.current?.click()}
                />
              )}
              {fileObject && (
                <IonIcon size="large" icon={checkmarkCircleOutline} />
              )}
            </>
          )}
        </CenterFlexRow>
        {commentImage && !savedImageDeleted && (
          <CenterFlexRow>
            <StyledImage
              onClick={() =>
                showModal('IMAGE_MODAL', {
                  hasClose: true,
                  clickOutsideClose: true,
                  children: <ZoomImageContainer src={commentImage} />,
                })
              }
              width="100px"
              height="100px"
              src={commentImage}
            />
          </CenterFlexRow>
        )}
      </CommentWrapperDiv>
    </div>
  );
};

import { IonIcon, useIonToast } from '@ionic/react';
import { filmOutline, imageOutline } from 'ionicons/icons';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useChangeContext } from '../../context/ChangeDetection';
import { useGlobalModalContext } from '../../context/GlobalModal';
import { Button } from '../../elements/Button';
import { FlexColumn, FlexRow } from '../../elements/genericWrappers';
import { Image } from '../../elements/Image';
import { Input } from '../../elements/Input';
import { CompetitionModel } from '../../models/competition';
import { IdeaModel } from '../../models/idea';
import {
  createCompetition,
  updateCompetition,
} from '../../services/competitionService';
import { createIdea, updateIdea } from '../../services/ideaService';
import { uploadFile } from '../../services/storageService';

import { mediaMapper } from '../../utils/mappers';
import { DateTimePicker } from '../DateTimePicker';
import { Modal } from '../Modal';

type ActionType = 'Create' | 'Edit';
type ObjectType = 'Competition' | 'Idea';

type GenericModalTypes = {
  actionType: ActionType;
  objectType: ObjectType;
  competition?: CompetitionModel | null;
  idea?: IdeaModel | null;
  open: boolean;
};

const Container = styled(FlexColumn)`
  width: 100%;
  gap: 15px;
`;

const FlexColumnLeft = styled(FlexColumn)`
  flex: 2;
  margin-bottom: 50px;
  gap: 10px;
`;
const FlexColumnRight = styled(FlexRow)`
  flex: 1;
  gap: 5px;
`;

const StyledP = styled.p`
  margin: 0;
`;

const StyledButton = styled(Button)`
  width: fit-content;
`;
const StyledForm = styled.form`
  width: 100%;
  padding: 0 20px;
`;
const StyledSubmit = styled(Button)`
  width: fit-content;
  position: relative;
  bottom: 20px;
  margin-left: auto;
  margin-top: auto;
`;

const StyledPreviewImage = styled(Image)`
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
`;
const StyledPreviewVideo = styled.video`
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
`;
const MediaContainer = styled.div`
  align-items: center;
  padding: 5px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;
const genericAction = {
  CreateIdea: async (data) => {
    data.payload.competitionId = data.competitionId;
    await createIdea(data.payload);
  },
  EditIdea: async (data) => {
    const payload: IdeaModel = {
      competitionId: data.competitionId,
      id: data.ideaId,
      ...data.payload,
    };
    await updateIdea(payload);
  },
  CreateCompetition: async (data) => {
    await createCompetition(data.payload);
  },
  EditCompetition: async (data) => {
    const payload: CompetitionModel = {
      id: data.competitionId,
      ...data.payload,
    };
    await updateCompetition(payload);
  },
};

const mapMedia = (payload, objectType) => {
  payload[mediaMapper[objectType].video] = payload.video;
  payload[mediaMapper[objectType].image] = payload.image;
  delete payload.video;
  delete payload.image;
  return payload;
};

export const GenericModal = ({
  actionType,
  objectType,
  competition,
  idea,
}: GenericModalTypes) => {
  const { hideModal } = useGlobalModalContext();
  const { setChange } = useChangeContext();
  const [previewVideo, setPreviewVideo] = useState<string>();
  const [previewImg, setPreviewImg] = useState<string>();
  const videoInput: any = useRef();
  const imgInput: any = useRef();
  const [img, setImg] = useState<any>();
  const [video, setVideo] = useState<any>();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  const [present] = useIonToast();

  const onSubmit = async (
    payload: {
      title: string;
      description: string;
      endDate?: Date;
      image?: string;
      video?: string;
    },
    e
  ) => {
    const uploadFolder = objectType === 'Competition' ? 'competition' : 'idea';
    if (video) {
      payload.video = await uploadFile(uploadFolder, video);
    }
    if (img) {
      payload.image = await uploadFile(uploadFolder, img);
    }
    const mappedPayload = mapMedia(payload, objectType);
    await genericAction[actionType + objectType]({
      payload: mappedPayload,
      competitionId: competition?.id || idea?.competitionId,
      ideaId: idea && idea.id,
    });
    e.preventDefault();
    setChange(new Date());
    hideModal();
  };

  const fileToLargeToast = (type) => {
    present({
      position: 'middle',
      color: 'warning',
      message: type === 'video' ? t('videoSize') : t('picSize'),
      duration: 3000,
    });
  };
  const handleChoose = (type) => {
    if (type === 'video') {
      videoInput.current.click();
    } else {
      imgInput.current.click();
    }
  };

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    const fileSize = file.size;
    const url = URL.createObjectURL(file);
    if (type === 'video') {
      // File size can be max 100Mb.
      const maxMegabyte = 100000000;
      if (fileSize <= maxMegabyte) {
        setVideo(file);
        setPreviewVideo(url);
      } else {
        fileToLargeToast('video');
      }
    } else {
      // File size can be max 10Mb.
      const maxMegabyte = 10000000;
      if (fileSize <= maxMegabyte) {
        setImg(file);
        setPreviewImg(url);
      } else {
        fileToLargeToast('image');
      }
    }
  };

  useEffect(() => {
    if (actionType === 'Edit') {
      if (objectType === 'Idea' && idea) {
        setValue('title', idea?.title);
        setValue('description', idea?.description);
        setValue('image', idea?.ideaImage);
        setValue('video', idea?.ideaVideo);
      }
      if (objectType === 'Competition' && competition) {
        setValue('title', competition?.title);
        setValue('description', competition?.description);
        setValue('endDate', new Date(competition?.endDate));
        setValue('image', competition?.backgroundImage);
        setValue('video', competition?.competitionVideo);
      }
    }
  }, []);

  return (
    <Modal header={t(`${objectType}.${actionType}.title`)}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Container>
          <Input
            multiline={true}
            register={{ ...register('title', { required: true }) }}
            label={t(`${objectType}.${actionType}.titleInput`)}
          />
          <Input
            multiline={true}
            register={{
              ...register('description', { required: true }),
            }}
            label={t(`${objectType}.${actionType}.descriptionInput`)}
          />
          <FlexRow>
            <FlexColumnLeft>
              <div>
                <StyledP>{t(`${objectType}.${actionType}.choosePic`)}</StyledP>
                <StyledButton onClick={() => handleChoose('img')}>
                  <IonIcon icon={imageOutline} />
                </StyledButton>
                <input
                  ref={imgInput}
                  hidden
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, 'img')}
                />
              </div>
              <div>
                <StyledP>{t(`${objectType}.${actionType}.chooseVid`)}</StyledP>
                <StyledButton onClick={() => handleChoose('video')}>
                  <IonIcon icon={filmOutline} />
                </StyledButton>
                <input
                  ref={videoInput}
                  hidden
                  className="VideoInput_input"
                  type="file"
                  onChange={(e) => handleFileChange(e, 'video')}
                  accept=".quicktime, .ogg, .webm, .mp4, .mov"
                />
              </div>
              {objectType === 'Competition' && (
                <Controller
                  control={control}
                  name="endDate"
                  defaultValue={new Date(new Date().getTime() + 12096e5)}
                  render={({ field: { onChange, value } }) => (
                    <DateTimePicker
                      label={t('datePickTitle')}
                      value={value}
                      onInput={onChange}
                    />
                  )}
                />
              )}
            </FlexColumnLeft>
            <FlexColumnRight>
              <MediaContainer>
                {previewImg && (
                  <StyledPreviewImage
                    src={previewImg}
                    width="auto"
                    height="auto"
                  ></StyledPreviewImage>
                )}
                {previewVideo && (
                  <StyledPreviewVideo
                    src={previewVideo}
                    width="auto"
                    height="auto"
                    controls
                  ></StyledPreviewVideo>
                )}
              </MediaContainer>
            </FlexColumnRight>
          </FlexRow>
          <StyledSubmit
            onClick=""
            type="submit"
            text={t('button.submit')}
            disabled={isSubmitting}
          />
        </Container>
      </StyledForm>
    </Modal>
  );
};

import { Modal } from '../Modal';
import styled from 'styled-components';
import { ListComponent } from '../NotificationDropDown';
import { useGlobalModalContext } from '../../context/GlobalModal';
const Content = styled.div`
  position: relative;
  width: 700px;
`;

export const NotificationModal = () => {
  const { hideModal } = useGlobalModalContext();
  return (
    <Modal>
      <Content>
        <ListComponent closeNotificationList={() => hideModal()} type="modal" />
      </Content>
    </Modal>
  );
};

import { Modal } from '../Modal';
import styled from 'styled-components';
import { Button } from '../../elements/Button';
import { addUserToCompetition } from '../../services/competitionService';
import { useAuthContext } from '../../context/Auth';
import { Card } from '../Card';
import { useGlobalModalContext } from '../../context/GlobalModal';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const Content = styled.div`
  position: relative;
  width: 900px;
  flex-direction: column;
  display: flex;
  gap: 20px;
}
`;
const DescriptionText = styled.p`
  text-align: center;
  margin: 0;
`;
const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const JoinChallengePreview = ({ challenge }: any) => {
  const { currentUser } = useAuthContext() as any;
  const history = useHistory();
  const { hideModal } = useGlobalModalContext();
  const { t } = useTranslation();

  const join = async () => {
    //FIXME Add user is executed 2 times in on th ehome page.
    const res = await addUserToCompetition({
      competitionId: challenge.id,
    });

    if (res.success) {
      history.push(`/challenge/${challenge.id}`);
      hideModal();
    }
  };
  return (
    <Modal header={t('congrats')} fullWidth={true}>
      <Content>
        <CenterWrapper>
          <DescriptionText>
            {challenge.host.name} {t('invitedCongratulation.invitedYouTo')}{' '}
            <strong>{challenge.title}</strong>
            {t('invitedCongratulation.challengePin')}{' '}
            <strong>{challenge.PIN}</strong>).{' '}
            {t('invitedCongratulation.gladToHaveYou')}
            {t('invitedCongratulation.willExpire')} {challenge.daysLeft}{' '}
            {t(
              challenge.daysLeft > 1
                ? 'invitedCongratulation.days'
                : 'invitedCongratulation.day'
            )}
            .
          </DescriptionText>
        </CenterWrapper>
        <CenterWrapper>
          <DescriptionText>{t('goodLuck')}</DescriptionText>
        </CenterWrapper>
        <CenterWrapper>
          <Card
            competitionPin={challenge.PIN}
            competitionId={challenge.id}
            title={challenge.title}
            description={challenge.description}
            backgroundImage={challenge.backgroundImage}
            user={challenge.host}
            participants={challenge.participants}
            ideaCount={challenge.ideaCount}
            video={challenge.competitionVideo}
            daysLeft={challenge.daysLeft}
            cardColor={'green'}
          />
        </CenterWrapper>
        <CenterWrapper>
          <Button text={t('button.continue')} onClick={join} />
        </CenterWrapper>
      </Content>
    </Modal>
  );
};

import { Modal } from '../Modal';
import styled from 'styled-components';
import { TextWrapper } from '../TextWrapper';
import { Button } from '../../elements/Button';
import { Input } from '../../elements/Input';
import { useState } from 'react';
import {
  getCompetitionViaPinCode,
  joinChallenge,
} from '../../services/competitionService';
import { useAuthContext } from '../../context/Auth';
import { useGlobalModalContext, MODAL_TYPES } from '../../context/GlobalModal';
import { useIonToast } from '@ionic/react';
import css from '@styled-system/css';
import { Image } from '../../elements/Image';
import { Headline } from '../../elements/Headlines';
import { useTranslation } from 'react-i18next';

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 400px;
  ${css({
    width: [],
    height: [],
  })}
`;
const LogoImage = styled(Image)`
  height: 100%;
  width: 200px;
  position: relative;
`;

const ImageContainer = styled.div`
  height: 20%;
  margin-top: 50px;
`;

const Flex = styled.div`
  margin-top: 30px;
  display: flex;
`;
const Logo = '/assets/logo/Logo_live.png';

export const JoinChallenge = () => {
  const { showModal } = useGlobalModalContext();
  const { authenticated } = useAuthContext();
  const [present] = useIonToast();
  const [pin, setPin] = useState('');
  const { t } = useTranslation();

  return (
    <Modal
      children={
        <Content>
          <ImageContainer>
            <LogoImage src={Logo} />
          </ImageContainer>
          <Headline title={t('welcomeModal.pinToJoin')} size={'h3'} />
          <Flex>
            <Input
              onChange={(e) => {
                setPin(e.target.value);
              }}
              label={t('joinCompetitionDialog.placePin')}
              type="text"
            />
            <Button
              onClick={() =>
                joinChallenge({
                  authenticated,
                  translation: t,
                  pin,
                  showModal,
                  toast: present,
                })
              }
              text={t('button.joinBtn')}
            />
          </Flex>
        </Content>
      }
    />
  );
};

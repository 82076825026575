import { Button } from '../elements/Button';
import { Input } from '../elements/Input';
import { IonCardTitle, IonIcon } from '@ionic/react';
import styled from 'styled-components';
import CheckIcon from '@mui/icons-material/Check';
import { image } from 'ionicons/icons';
import { mail } from 'ionicons/icons';
import PhoneInput from 'react-phone-input-2';
import css from '@styled-system/css';

import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const CheckIconStyled = styled(CheckIcon)`
  position: absolute;
  margin-top: 10px;
`;

export const NavigationContainer = styled.span`
  text-align: center;
`;

const FullWidthInput = styled(Input)`
  width: 100%;
`;

export const CenterTitle = styled(IonCardTitle)`
  text-align: center;
`;

const CenterDivContent = styled.div`
  text-align: center;
  width: fit-content;
`;

const SubmitButtonWrapper = styled.div`
  margin: 20px;
  text-align: center;
`;

export const FormWrapper = styled.div<any>`
  height: ${(props) => (props.height ? props.height : 'fit-content')};
  ${css({
    width: ['100%', '100%', 'fit-content', 'fit-content'],
  })}
`;

export const StyledForm = styled.form<any>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 20px;
`;

export const PasswordField = ({ register, errors }) => {
  const { t } = useTranslation();
  return (
    <>
      <FullWidthInput
        label={t('password')}
        type="password"
        register={{
          ...register('password', { required: true, minLength: 6 }),
        }}
      />
      {errors && errors.password && (
        <span>{t('validationError.minimumCharacters')}</span>
      )}
    </>
  );
};

export const EmailField = ({ register, errors }) => {
  const { t } = useTranslation();
  return (
    <>
      <FullWidthInput
        label={t('email')}
        register={{
          ...register('email', {
            required: true,
            pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
          }),
        }}
      />
      {errors && errors.email && <span>{t('validationError.email')}</span>}
    </>
  );
};

export const FirstNameField = ({ register, errors }) => {
  const { t } = useTranslation();
  return (
    <>
      <FullWidthInput
        label={t('firstName')}
        register={{ ...register('firstName', { required: true }) }}
      />
      {errors && errors.email && (
        <span>{t('validationError.fieldRequired')}</span>
      )}
    </>
  );
};

export const LastNameField = ({ register, errors }) => {
  const { t } = useTranslation();
  return (
    <>
      <FullWidthInput
        label={t('lastName')}
        register={{ ...register('lastName', { required: true }) }}
      />
      {errors && errors.email && (
        <span>{t('validationError.fieldRequired')}</span>
      )}
    </>
  );
};

export const ProfileImageField = ({ register, watch }) => {
  const { t } = useTranslation();
  return (
    <CenterDivContent>
      <input
        hidden
        id="selectedFile"
        type="file"
        accept="image/*"
        {...register('profilePicture')}
      />
      <Button
        text={t('button.uploadProfileImage')}
        onClick={() => {
          document.getElementById('selectedFile')?.click();
        }}
      >
        <IonIcon slot="icon-only" icon={image} />
      </Button>
      {watch('profilePicture')?.length > 0 && <CheckIconStyled />}
    </CenterDivContent>
  );
};

export const Submit = ({ text, icon = mail, disabled = false }) => (
  <SubmitButtonWrapper>
    <Button disabled={disabled} text={text} type="submit">
      <IonIcon slot="icon-only" icon={icon} />
    </Button>
  </SubmitButtonWrapper>
);

export const TextNavigationButton = styled.span`
  font-family: 'Mouse Memoirs', sans-serif;
  text-align: center;
  font-weight: 400;
  line-height: 1.5;
  font-size: 18px;
  width: fit-content;
  padding: 0;
  height: fit-content;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 5px;
`;
export const NavigationButton = styled(Button)`
  font-family: 'Mouse Memoirs', sans-serif;
  text-align: center;
  font-weight: 400;
  line-height: 1.5;
  font-size: 18px;
  width: fit-content;
  padding: 0;
  height: fit-content;
  p {
    padding: 0;
    text-decoration: underline;
    margin: 0;
  }
`;
const StyledErrorMessage = styled.span`
  color: var(--ion-color-danger);
  text-align: center;
`;
export const ErrorMessage = ({ error }) => {
  const { t } = useTranslation();
  return <>{error && <StyledErrorMessage>{t(error)}</StyledErrorMessage>}</>;
};

export const PhoneNumberField = ({ register, onChange, errors, ...rest }) => {
  const { t } = useTranslation();
  return (
    <>
      <PhoneInput
        {...rest}
        {...register('phoneNumber', { required: true })}
        onChange={onChange}
      />
      {errors && errors.phoneNumber && (
        <span>{t('validationError.fieldIsRequired')}</span>
      )}
    </>
  );
};
export const VerifyPhoneNumberField = ({ register, errors, ...rest }) => {
  const { t } = useTranslation();
  return (
    <>
      <FullWidthInput
        label={t('smsCode')}
        {...rest}
        register={{ ...register('smsCode', { required: true }) }}
        errors={errors}
      />
      {errors && errors.verifyPhoneNumber && (
        <span>{t('validationError.fieldRequired')}</span>
      )}
    </>
  );
};

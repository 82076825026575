import { createContext, useContext, useEffect, useState } from 'react';

type sharedChangeType = {
  setChange: (date: Date) => void;
  change?: Date;
};
const initialState: sharedChangeType = {
  setChange: () => {},
};
const ChangeContext = createContext(initialState);

export const useChangeContext = () => useContext(ChangeContext);

export function ChangeContextProvider({ children }) {
  const [changeState, setChangeState] = useState<any>();
  const change = changeState;
  const setChange = (change: Date) => {
    setChangeState({
      ...change,
    });
  };
  const sharedChange: sharedChangeType = {
    setChange,
    change,
  };
  return (
    <ChangeContext.Provider value={sharedChange}>
      {children}
    </ChangeContext.Provider>
  );
}

export function ChangeContextConsumer({ children }) {
  return <ChangeContext.Consumer>{children}</ChangeContext.Consumer>;
}

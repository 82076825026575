import styled from 'styled-components';
import { useState } from 'react';
import { logoFacebook, phonePortrait, mail } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

import { Modal } from '../Modal';
import { useGlobalModalContext } from '../../context/GlobalModal';
import { FacebookLogin } from '../../services/authService';
import { Button } from '../../elements/Button';
import { FlexColumn } from '../../elements/genericWrappers';
import { FirebaseForm } from '../../forms/AuthEmail';
import { FirebaseResetPasswordForm } from '../../forms/AuthResetPassword';
import { FormWrapper } from '../FormComponents';
import { GeninForm } from '../../forms/AuthGenin';
import { PhoneForm } from '../../forms/AuthPhone';
import { useAppContext } from '../../context/Shared';
import { useTranslation } from 'react-i18next';

const StyledModal = styled(Modal)`
  max-width: 98%;
  max-height: 98%;
  width: 100%;
  height: 100%;
  margin: auto;
  flex-direction: column;
`;
const StyledFlexColumn = styled(FlexColumn)`
  justify-content: center;
  align-items: center;
`;
const FullWithButton = styled(Button)`
  width: 100%;
  .btnText {
    text-transform: none;
  }
`;

const FORM_TYPES = {
  FIREBASE_RESET_PASSWORD: 'FIREBASE_RESET_PASSWORD',
  FIREBASE_FORM: 'FIREBASE_FORM',
  GENIN_FORM: 'GENIN_FORM',
  PHONE_FORM: 'PHONE_FORM',
};
const FORMS = {
  FIREBASE_RESET_PASSWORD: FirebaseResetPasswordForm,
  FIREBASE_FORM: FirebaseForm,
  GENIN_FORM: GeninForm,
  PHONE_FORM: PhoneForm,
};
export const AutheticationModal = (props) => {
  const { hideModal } = useGlobalModalContext();
  const { device } = useAppContext();
  const [form, setForm] = useState<any>();
  const { t } = useTranslation();
  const [header, setHeader] = useState<any>(t('loginTitle'));
  const Form = FORMS[form];
  return (
    <StyledModal
      header={header}
      fullScreen={true}
      close={hideModal}
      open={true}
      {...props}
    >
      {form ? (
        <FormWrapper>
          <Form setHeader={setHeader} setForm={setForm} />
        </FormWrapper>
      ) : (
        <StyledFlexColumn>
          <FullWithButton
            size="large"
            color="#4267b2"
            onClick={() => {
              FacebookLogin(device);
              hideModal();
            }}
            text={t('facebookBtn')}
          >
            <IonIcon slot="start" icon={logoFacebook} />
          </FullWithButton>

          <FullWithButton
            size="large"
            color="#29832e"
            onClick={() => setForm(FORM_TYPES.FIREBASE_FORM)}
            text={t('emailBtn')}
          >
            <IonIcon slot="start" icon={mail} />
          </FullWithButton>

          <FullWithButton
            size="large"
            color="#ce772d"
            text={t('smsBtn')}
            onClick={() => setForm(FORM_TYPES.PHONE_FORM)}
          >
            <IonIcon slot="start" icon={phonePortrait} />
          </FullWithButton>

          <FullWithButton
            size="large"
            color="#47a089"
            onClick={() => setForm(FORM_TYPES.GENIN_FORM)}
            text={t('geninBtn')}
          >
            <IonIcon slot="start" src={'assets/icon/genInIcon.svg'} />
          </FullWithButton>
        </StyledFlexColumn>
      )}
    </StyledModal>
  );
};

import { IonAvatar } from '@ionic/react';
import { Image } from '../elements/Image';
import styled from 'styled-components';
import TooltipComponent from './Tooltip';

type avatarProps = {
  avatarSrc?: string;
  hoverText?: string;
  width?: string;
  height?: string;
  className?: string;
};
const StyledIonAvatar = styled(IonAvatar)<
  Pick<avatarProps, 'width' | 'height'>
>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
const fallbackImage = 'assets/wlimaskot.jpg';

export const Avatar = ({
  avatarSrc = fallbackImage,
  hoverText = '',
  height = '48px',
  width = '48px',
  className,
}: avatarProps) => {
  return (
    <TooltipComponent text={hoverText}>
      <StyledIonAvatar height={height} width={width} className={className}>
        <Image
          src={avatarSrc}
          alt={hoverText}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = fallbackImage;
          }}
        />
      </StyledIonAvatar>
    </TooltipComponent>
  );
};

import { getData, deleteData, putData } from '../utils/requestHandlers';
import console from '../utils/log';
const endpoint = process.env.REACT_APP_API;
const route = '/notifications';
const api = endpoint + route;

export const getNotifications = async () => {
  try {
    const res = await getData(`${api}/getAll`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getNotification = async (notificationId) => {
  try {
    const res = await getData(`${api}/get/${notificationId}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteNotification = async (notificationId) => {
  try {
    const res = await deleteData(`${api}/delete/${notificationId}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const deleteAllNotifications = async () => {
  try {
    const res = await deleteData(`${api}/deleteAll`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const changeNotificationToRead = async ({ notificationId }) => {
  try {
    const res = await putData(`${api}/changeStatus/${notificationId}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

import { UserModel } from '../models/user';
import { getData, postData, putData } from '../utils/requestHandlers';
import { MapToFormData } from '../utils/mappers';
import console from '../utils/log';

const endpoint = process.env.REACT_APP_API;
const route = '/users';
const api = endpoint + route;

export const addIdeaToUser = async ({ userId, ideaId }) => {
  try {
    return await putData(`${api}/newIdea`, { userId, ideaId });
  } catch (error) {
    console.log(error);
  }
};
export const addCompetitionToUser = async ({ userId, competitionId }) => {
  try {
    return await putData(`${api}/newCompetition`, {
      userId,
      competitionId,
    });
  } catch (error) {
    console.log(error);
  }
};
export const addBadgeToUser = async ({ badgeId, userId }) => {
  try {
    return await putData(`${api}/newBadge`, { badgeId, userId });
  } catch (error) {
    console.log(error);
  }
};

export const createUserDoc = async (user: UserModel) => {
  const userFormdata = MapToFormData(user);
  try {
    const res = await postData(`${api}/create`, userFormdata);
    return res.data;
  } catch (error) {
    console.log(error);

    return null;
  }
};

export const getUserDoc = async (userId) => {
  try {
    const res = await getData(`${api}/get/${userId}`);
    return res.data;
  } catch (error) {}
};
export const updateUserDoc = async (user) => {
  const userFormdata = MapToFormData(user);

  try {
    return await putData(`${api}/update`, userFormdata);
  } catch (error) {
    console.log(error);
  }
};

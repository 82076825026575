type checkRoleTypes = {
  authorId?: string | undefined;
  hostId?: string | undefined;
  authId?: string | undefined;
};

type identificationType = {
  userId?: string | undefined;
  authId?: string | undefined;
  hostId?: string | undefined;
};

export const isAuthor = ({ authorId, authId }: checkRoleTypes) => {
  if (authorId === authId) {
    return true;
  } else {
    return false;
  }
};

export const isHost = ({ hostId, authId }: checkRoleTypes) => {
  if (hostId === authId) {
    return true;
  } else {
    return false;
  }
};

export const isUser = ({ authorId, hostId, authId }: checkRoleTypes) => {
  if (authorId !== authId && hostId !== authId) {
    return true;
  } else {
    return false;
  }
};

export const hasHost = ({ userId, hostId }: identificationType) => {
  if (userId === hostId) {
    return true;
  } else {
    return false;
  }
};

export const isMe = ({ userId, authId }: identificationType) => {
  if (userId === authId) {
    return true;
  } else {
    return false;
  }
};

import { useState, useEffect } from 'react';
import { useChangeContext } from '../context/ChangeDetection';
import { CompetitionModel } from '../models/competition';
import {
  getPrivateChallenges,
  getPublicChallenges,
  getEndedChallenges,
  getCompetition,
} from '../services/competitionService';

export const useFetchPrivateChallenges = (user: any) => {
  const [privateChallenges, setPrivateChallenges] = useState<
    CompetitionModel[] | any
  >(null);
  const { change } = useChangeContext();

  useEffect(() => {
    if (!user) {
      setPrivateChallenges([]);
      return;
    }

    try {
      getPrivateChallenges().then((res) => {
        const challenges = Array.isArray(res?.data) ? res?.data : [];
        setPrivateChallenges(challenges);
      });
    } catch (error) {}
  }, [user, change]);
  return privateChallenges;
};

export const useFetchPublicChallenges = (user) => {
  const [publicChallenges, setPublicChallenges] = useState<
    CompetitionModel[] | any
  >(null);
  const { change } = useChangeContext();

  useEffect(() => {
    if (!user) {
      setPublicChallenges([]);

      return;
    }
    try {
      getPublicChallenges().then((res) => {
        const challenges = Array.isArray(res?.data) ? res?.data : [];

        setPublicChallenges(challenges);
      });
    } catch (error) {}
  }, [user, change]);

  return publicChallenges;
};

export const useFetchEndedChallenges = (user: any) => {
  const [endedChallenges, setEndedChallenges] = useState<
    CompetitionModel[] | any
  >(null);
  const { change } = useChangeContext();
  useEffect(() => {
    if (!user) {
      setEndedChallenges([]);
      return;
    }
    try {
      getEndedChallenges().then((res) => {
        const challenges = Array.isArray(res?.data) ? res?.data : [];
        setEndedChallenges(challenges);
      });
    } catch (error) {}
  }, [user, change]);
  return endedChallenges;
};
export const useFetchChallenge = (competitionId: string) => {
  const [competition, setCompetition] = useState<CompetitionModel>();
  const { change } = useChangeContext();

  useEffect(() => {
    try {
      if (competitionId) {
        getCompetition({ competitionId }).then((res) => {
          const competition = res?.data;

          setCompetition(competition);
        });
      }
    } catch (error) {}
  }, [competitionId, change]);
  return competition;
};

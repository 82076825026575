import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonIcon,
} from '@ionic/react';
import { CardType } from '../types/card';
import { Image } from '../elements/Image';
import styled from 'styled-components';
import { Avatar } from '../elements/Avatar';
import { Label } from '../elements/Label';
import { bulb, people, stopwatch, playCircle, chatbox } from 'ionicons/icons';
import { hostAuthorMap } from '../utils/mappers';
import { colors } from '../utils/colorPalette';
import css from '@styled-system/css';
import { useGlobalModalContext, MODAL_TYPES } from '../context/GlobalModal';
import { HeartLabel } from './HeartLabel';
import { isAuthor } from '../utils/checkRole';
import { useAuthContext } from '../context/Auth';
import { useTranslation } from 'react-i18next';

const StyledIonCard = styled(IonCard)<Pick<CardType, 'cardColor'>>`
  max-width: 350px;
  width: 290px;
  margin: 0;
  background-color: ${({ cardColor }) => cardColor && colors[cardColor]};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  :hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
  ${css({
    maxWidth: ['290px', '350px', '400px', '320px', '400px'],
    flex: ['0 0 100%', '0 0 100%', '0 0 100%', '1 0 25%', '10%'],
    width: ['290px', '350px', '400px', '320px', '320px'],
  })}
`;

const StyledAvatar = styled(Avatar)`
  position: absolute;
  bottom: -1rem;
`;
const WinnerAvatar = styled(Avatar)`
  display: inline-block;
`;

const WinnerLabelWrapper = styled.div`
  background-color: ${colors.gold};
  width: 100%;
  position: absolute;
  height: 100px;
  top: 150px;
  z-index: 1000;
  text-align: center;
`;

const WinnerLabel = styled.p`
  margin-bottom: 0px;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 300px;
  align-items: baseline;
`;
const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  -ms-interpolation-mode: bicubic;
`;
const StyledLabel = styled(Label)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
`;
const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 5px;
  top: 10%;
  width: fit-content;
`;
const TitleWithLineClamp = styled(IonCardTitle)`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  max-height: 30px;
`;
const ContentWrapper = styled.div`
  margin-top: 20px;
  height: 100%;
  ${css({
    minHeight: ['150px', '150px', '150px', '150px', '150px'],
  })}
`;
const DescriptionWithLineClamp = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 60px;
`;

const StyledIcon = styled(IonIcon)`
  font-size: x-large;
`;

const PlayIcon = styled(IonIcon)`
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 30px;
`;

export const Card = ({
  title,
  ideaId,
  backgroundImage = 'assets/wlimaskot.jpg',
  user,
  participants = [],
  description,
  ideaCount = 0,
  daysLeft = 0,
  cardState = 'active',
  cardColor = 'green',
  cardType = 'challenge',
  lovedBy = [],
  commentCount = 0,
  video,
  className,
  competitionPin,
  competitionId,
  winner,
  ...rest
}: CardType) => {
  //TODO Should be mapped in the database
  const userMapped = hostAuthorMap({ user: user });
  const { currentUser } = useAuthContext();
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const openInviteModal = () => {
    showModal(MODAL_TYPES.GENERATE_PIN_MODAL, {
      competitionPin,
      participants,
      competitionId,
      hostId: userMapped.userId,
    });
  };

  return (
    <StyledIonCard className={className} cardColor={cardColor} {...rest}>
      {cardType === 'challenge' ? (
        <LabelWrapper>
          <StyledLabel text={ideaCount + t('ideaText')}>
            <StyledIcon icon={bulb} />
          </StyledLabel>
          <StyledLabel
            text={participants?.length + t('participantText')}
            onClick={(e) => {
              e.preventDefault();
              openInviteModal();
            }}
          >
            <StyledIcon icon={people} />
          </StyledLabel>
          <StyledLabel text={daysLeft + t('dayText')}>
            <StyledIcon icon={stopwatch} />
          </StyledLabel>
        </LabelWrapper>
      ) : (
        <LabelWrapper>
          <HeartLabel
            canLike={
              !isAuthor({
                authorId: userMapped?.userId,
                authId: currentUser?.uid,
              })
            }
            lovedBy={lovedBy}
            competitionId={competitionId}
            ideaId={ideaId}
            text={t('lovesText')}
          />
          <StyledLabel text={commentCount + t('commentsText')}>
            <StyledIcon icon={chatbox} />
          </StyledLabel>
        </LabelWrapper>
      )}
      {winner && (
        <WinnerLabelWrapper>
          <WinnerLabel>{t('popularIdea')}</WinnerLabel>
          <p>
            <WinnerAvatar
              avatarSrc={winner.user.image}
              hoverText={winner.user.name}
            />
          </p>
        </WinnerLabelWrapper>
      )}
      <ImageWrapper>
        <StyledImage src={backgroundImage} />
        <StyledAvatar
          avatarSrc={userMapped.profilePicture}
          hoverText={userMapped.name}
        />
        {video && <PlayIcon icon={playCircle} />}
      </ImageWrapper>
      <ContentWrapper>
        <IonCardHeader>
          <TitleWithLineClamp>{title}</TitleWithLineClamp>
        </IonCardHeader>
        <IonCardContent>
          <DescriptionWithLineClamp>{description}</DescriptionWithLineClamp>
        </IonCardContent>
      </ContentWrapper>
    </StyledIonCard>
  );
};

import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../config/firebase';
import console from '../utils/log';
import shortid from 'shortid';
import path from 'path';
// 'file' comes from the Blob or File API

/* 
Husk limit på 10mb for billeder og 100mb for video;
*/
export const uploadFile = async (type: folderTypes, file) => {
  try {
    const fileUploadPath = uniqueFileName(type, file);
    const storageRef = ref(storage, fileUploadPath);
    await uploadBytes(storageRef, file);
    const mediaUrl = await getDownloadURL(storageRef);
    return mediaUrl;
  } catch (error) {
    console.log(error);
  }
};
const uniqueFileName = (type: folderTypes, file) => {
  const filename = file.name;
  const fileExt = filename.split('.').pop();
  const generatedShortId = shortid.generate();
  const newFileName = `${generatedShortId}_${Date.now()}.${fileExt}`;
  const storagePath = path.join(getFolder(type), newFileName);
  return storagePath;
};
const getFolder = (fieldname: folderTypes) => {
  const folders = {
    competition: 'competitions',
    idea: 'ideas',
    comment: 'comments',
    profilePicture: 'profiles',
  };
  return folders[fieldname] || '';
};

type folderTypes = 'competition' | 'idea' | 'comment' | 'profilePicture';

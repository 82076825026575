import { IonHeader, IonNav, IonRouterLink, useIonToast } from '@ionic/react';
import { Image } from '../elements/Image';
import styled from 'styled-components';
import { NavigationBarType } from '../types/navigation';
import { useAppContext } from '../context/Shared';
import { Input } from '../elements/Input';
import { Button } from '../elements/Button';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { joinChallenge } from '../services/competitionService';
import { MODAL_TYPES, useGlobalModalContext } from '../context/GlobalModal';
import { useAuthContext } from '../context/Auth';

const Header = styled(IonHeader)<any>`
  ${(props) =>
    props.isNative
      ? `
    height: 90px;
  `
      : `
    height: 68px;
  `}
  background-color: var(--ion-color-primary);
  display: flex;
  justify-content: space-between;
`;
const LogoImage = styled(Image)`
  height: 100%;
  position: relative;
  object-fit: contain;
`;
const StyledIonNav = styled(IonNav)<any>`
  ${(props) =>
    props.isNative &&
    `
      padding-top: 20px;
`}
  margin: 10px;
  display: flex;
  justify-content: space-between;
`;
const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  margin: 0px 20px;
`;
const StyledButton = styled(Button)`
  --background: var(--ion-color-secondary);
  height: auto;
`;
const NavigationBar = ({ children }: NavigationBarType) => {
  //Should be fetched for a env file
  const Logo = '/assets/logo/Logo_live.png';
  const { device } = useAppContext();
  const [present] = useIonToast();
  const { showModal } = useGlobalModalContext();
  const { authenticated } = useAuthContext();

  const isNative = device?.isApp;
  const { t } = useTranslation();
  const [pin, setPin] = useState('');

  const inputChange = (event) => {
    setPin(event.target.value);
  };
  return (
    <Header isNative={isNative}>
      <StyledIonNav isNative={isNative}>
        <IonRouterLink href="/">
          <LogoImage src={Logo} />
        </IonRouterLink>
        {device?.isDesktop && (
          <Flex>
            <Input
              onChange={inputChange}
              label={t('enterPIN')}
              type="text"
            ></Input>
            <StyledButton
              onClick={() =>
                joinChallenge({
                  authenticated,
                  translation: t,
                  pin,
                  showModal,
                  toast: present,
                })
              }
              text={t('button.join')}
            />
          </Flex>
        )}
        {<ActionWrapper>{children}</ActionWrapper>}
      </StyledIonNav>
    </Header>
  );
};
export default NavigationBar;

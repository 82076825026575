import {
  EmailField,
  PasswordField,
  NavigationButton,
  Submit,
  StyledForm,
  NavigationContainer,
  TextNavigationButton,
} from '../components/FormComponents';
import { GeninLogin } from '../services/authService';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthContext } from '../context/Auth';
import { useGlobalModalContext } from '../context/GlobalModal';
import { useTranslation } from 'react-i18next';
import { Anchor } from '../elements/Anchor';

export const GeninForm = ({ setHeader, setForm }) => {
  const { setCurrentUser } = useAuthContext();
  const { hideModal } = useGlobalModalContext();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    setHeader(t('pleaseLogin'));
    reset();
  }, []);
  const onSubmit = async (data) => {
    const userDoc = await GeninLogin(data);
    if (userDoc) {
      setCurrentUser(userDoc);
    }
    hideModal();
  };
  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <EmailField register={register} errors={errors} />
      <PasswordField register={register} errors={errors} />
      <Submit disabled={isSubmitting} text={t('button.login')} />
      <NavigationContainer>
        {t('geninAlready')}{' '}
        <Anchor text={t('here')} onClick={() => setForm('')} />
      </NavigationContainer>
    </StyledForm>
  );
};

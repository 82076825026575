import { createContext, useContext, useState } from 'react';
import { useGetDevice } from '../hooks/deviceDetection';
import { customThemeWithBreakPoints } from '../config/styles';

type sharedStateType = {
  device?: {
    isMobile: boolean;
    isTablet: boolean;
    isBigTablet: boolean;
    isDesktop: boolean;
    isApp: boolean;
    isNative: string;
  };
  setCurrentRouteParams?: ({
    challengeId,
    ideaId,
  }: {
    challengeId: string;
    ideaId: string;
  }) => void;
  getRouteParams?: { challengeId: string; ideaId: string };
};

const AppContext = createContext<sharedStateType>({});

export function AppContextProvider({ children }) {
  const [getRouteParams, setRouteParams] = useState<any>();

  const setCurrentRouteParams = ({
    challengeId,
    ideaId,
  }: {
    challengeId: string;
    ideaId: string;
  }) => {
    setRouteParams({
      challengeId,
      ideaId,
    });
  };
  let sharedState: sharedStateType = {
    device: useGetDevice(customThemeWithBreakPoints.breakpoints),
    setCurrentRouteParams,
    getRouteParams,
  };

  return (
    <AppContext.Provider value={sharedState}>{children}</AppContext.Provider>
  );
}
export function AppContextConsumer({ children }) {
  return <AppContext.Consumer>{children}</AppContext.Consumer>;
}

export function useAppContext(): sharedStateType {
  return useContext(AppContext);
}

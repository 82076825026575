import { useState, useRef } from 'react';
import { useAuthContext } from '../context/Auth';
import { StyledIonContent } from '../elements/genericWrappers';
import styled from 'styled-components';
import css from '@styled-system/css';
import { Button } from '../elements/Button';
import { IonIcon } from '@ionic/react';
import {
  exitOutline,
  createOutline,
  imageOutline,
  checkmarkCircleOutline,
  close,
  informationCircle,
} from 'ionicons/icons';
import { Logout } from '../services/authService';
import { Badge } from '../elements/Badge';
import { Image } from '../elements/Image';
import { Input } from '../elements/Input';
import { updateUserDoc } from '../services/userService';
import { useHistory } from 'react-router-dom';
import { useChangeContext } from '../context/ChangeDetection';
import ErrorBoundary from '../components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { uploadFile } from '../services/storageService';

const FlagImage = styled(Image)`
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  width: 50px;
  position: relative;
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  cursor: pointer;
`;
const ContentWrapper = styled.div`
  margin: 10px;
  word-break: break-word;
`;

const BadgeWrapper = styled.div`
  margin: 30px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  flex-wrap: wrap;
  ${css({
    justifyContent: ['center', 'center', 'flex-start', 'flex-start'],
  })}
`;
const HeaderTitle = styled.h1`
  margin: 0px;
  display: inline-block;
`;
const StyledButton = styled(Button)`
  display: inline-block;
`;

const InlineButton = styled(Button)`
  height: 48px;
  display: inline-block;
`;
const StyledButtonFloatRight = styled(Button)`
  margin: 0px;
  float: right;
`;

const Table = styled.table`
  line-height: 1;
  width: 100%;
`;

const ExtraSpaceTR = styled.tr`
  line-height: 3;
`;
const ExtraSpaceTD = styled.td`
  line-height: 4;
`;
const SaveEditIcon = styled(IonIcon)`
  height: 100%;
  width: 48px;
`;
const TableWrapper = styled.div`
  margin-top: 20px;
  max-width: 800px;
`;
const PageDivider = styled.hr`
  margin-right: 10px;
  border-top: 2px solid #d7d8da;
  margin-left: 15px;
`;

const ProfilePage = () => {
  const { t, i18n } = useTranslation();
  const DanishFlagSrc = '/assets/icon/dk.svg';
  const EnglishFlagSrc = '/assets/icon/gb.svg';
  const [nameBeingEdited, setNameBeingEdited] = useState(false);
  const { currentUser, authenticated, setCurrentUser } =
    useAuthContext() as any;
  const { setChange } = useChangeContext();
  const history = useHistory();
  const { firstName, lastName, email, myBadges, defaultLanguage } =
    currentUser || {};
  const [newName, setNewName] = useState(firstName + ' ' + lastName);
  const fileInput: any = useRef(null);

  const badges = [
    {
      title: t('badges.welcomeBadge.title'),
      info: t('badges.welcomeBadge.info'),
      id: 'WelcomeOnBoard',
    },
    {
      title: t('badges.ideatorBadge.title'),
      info: t('badges.ideatorBadge.info'),
      id: 'Ideator',
    },
    {
      title: t('badges.goodIdeaBadge.title'),
      info: t('badges.goodIdeaBadge.info'),
      id: 'GoodIdea',
    },
  ];

  const logout = async () => {
    sessionStorage.clear();
    setCurrentUser(false);
    setChange(new Date());
    await Logout();
    history.replace('/home');
    window.location.reload();
  };
  //TODO make file uplaod work
  const onSelectFile = async (selectEvent: any) => {
    const file = selectEvent.target.files[0];
    const newFileUrl = await uploadFile('profilePicture', file);

    if (newFileUrl) {
      const res = await updateUserDoc({
        profilePicture: newFileUrl,
      });

      const userDoc = res.data;
      setCurrentUser(userDoc);
    }
  };

  const inputChangeEvent = (event) => {
    setNewName(event.target.value);
  };
  const saveName = async () => {
    const NewfirstName = newName.split(' ')[0];
    const NewlastName = newName.split(' ').slice(1).join(' ');
    const res = await updateUserDoc({
      firstName: NewfirstName,
      lastName: NewlastName,
    });
    const userDoc = res.data;
    setCurrentUser(userDoc);
    setNameBeingEdited(false);
  };
  const flagClicked = async (countryCode) => {
    const defaultLanguage = countryCode;
    const res = await updateUserDoc({ defaultLanguage });
    const userDoc = res.data;
    setCurrentUser(userDoc);
  };

  try {
    return (
      <>
        {authenticated && (
          <StyledIonContent>
            <ContentWrapper>
              <HeaderTitle>{t('profilePage.myProf')}</HeaderTitle>
              <StyledButtonFloatRight
                onClick={() => history.go(-1)}
                fill="clear"
              >
                <IonIcon slot="icon-only" icon={close} />
              </StyledButtonFloatRight>
              <StyledButtonFloatRight
                target="_blank"
                href="https://www.weloveideas.com/"
                fill="clear"
              >
                <IonIcon slot="icon-only" icon={informationCircle} />
              </StyledButtonFloatRight>
              <StyledButtonFloatRight onClick={() => logout()} fill="clear">
                <IonIcon slot="icon-only" icon={exitOutline} />
              </StyledButtonFloatRight>
            </ContentWrapper>
            {currentUser && (
              <>
                <ContentWrapper>
                  <TableWrapper>
                    <Table>
                      <tbody>
                        <tr>
                          <td>{t('profilePage.name')}</td>
                          {nameBeingEdited ? (
                            <td>
                              <Input
                                label={t('profilePage.name')}
                                onChange={inputChangeEvent}
                                defaultValue={newName}
                              />
                              <InlineButton
                                fill="clear"
                                onClick={() => {
                                  saveName();
                                }}
                              >
                                <SaveEditIcon
                                  slot="icon-only"
                                  icon={checkmarkCircleOutline}
                                />
                              </InlineButton>
                            </td>
                          ) : (
                            <td>
                              {firstName} {lastName}
                              <StyledButton
                                fill="clear"
                                onClick={() => {
                                  setNameBeingEdited(true);
                                }}
                              >
                                <IonIcon
                                  slot="icon-only"
                                  icon={createOutline}
                                />
                              </StyledButton>
                            </td>
                          )}
                        </tr>
                        <tr>
                          <td>{t('profilePage.email')}</td>
                          <td>{email}</td>
                        </tr>
                        <tr>
                          <td>{t('profilePage.changePic')}</td>
                          <ExtraSpaceTD>
                            <input
                              ref={fileInput}
                              hidden
                              type="file"
                              accept="image/*"
                              onChange={onSelectFile}
                            />
                            <StyledButton
                              onClick={() => {
                                fileInput?.current?.click();
                              }}
                            >
                              <IonIcon slot="icon-only" icon={imageOutline} />
                            </StyledButton>
                          </ExtraSpaceTD>
                        </tr>
                        <ExtraSpaceTR>
                          <td>{t('profilePage.selectLang')}</td>
                          <td>
                            <FlagImage
                              src={EnglishFlagSrc}
                              selected={defaultLanguage === 'en'}
                              onClick={() => {
                                i18n.changeLanguage('en');
                                flagClicked('en');
                              }}
                            />
                            <FlagImage
                              selected={defaultLanguage === 'dk'}
                              src={DanishFlagSrc}
                              onClick={() => {
                                i18n.changeLanguage('da');
                                flagClicked('dk');
                              }}
                            />
                          </td>
                        </ExtraSpaceTR>
                      </tbody>
                    </Table>
                  </TableWrapper>
                </ContentWrapper>
                <PageDivider />
                <BadgeWrapper>
                  {myBadges &&
                    badges.map((badge, index) => {
                      const badgeActive = myBadges.some(
                        (e) => e.id === badge.id
                      );
                      return (
                        <Badge
                          key={`badge-${index}`}
                          iconActive={badgeActive}
                          title={badge.title}
                          text={badge.info}
                        />
                      );
                    })}
                </BadgeWrapper>
              </>
            )}
          </StyledIonContent>
        )}
      </>
    );
  } catch (error) {
    return <ErrorBoundary error={error} />;
  }
};

export default ProfilePage;

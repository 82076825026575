import { IdeaModel } from '../models/idea';
import {
  deleteData,
  getData,
  postData,
  putData,
} from '../utils/requestHandlers';
import { MapToFormData } from '../utils/mappers';
import console from '../utils/log';
const endpoint = process.env.REACT_APP_API;
const route = '/ideas';
const api = endpoint + route;

export const getIdea = async ({
  competitionId,
  ideaId,
}: {
  competitionId: string | undefined;
  ideaId: string | undefined;
}) => {
  try {
    return await getData(`${api}/getSingle/${competitionId}/${ideaId}`);
  } catch (error) {
    console.log(error);
  }
};

export const getAllIdeas = async ({
  competitionId,
}: {
  competitionId: string;
}) => {
  try {
    return await getData(`${api}/getAll/${competitionId}`);
  } catch (error) {
    console.log(error);
  }
};

export const createIdea = async (idea: IdeaModel) => {
  const formData = MapToFormData(idea);
  try {
    await postData(`${api}/create`, formData);
  } catch (error) {
    console.log(error);
  }
};
export const updateIdea = async (
  idea: Pick<
    IdeaModel,
    'ideaImage' | 'ideaVideo' | 'description' | 'title' | 'competitionId' | 'id'
  >
) => {
  const formData = MapToFormData(idea);
  try {
    await putData(`${api}/update`, formData);
  } catch (error) {
    console.log(error);
  }
};

export const loveIdea = async ({
  competitionId,
  ideaId,
}: {
  competitionId: string | undefined;
  ideaId: string | undefined;
}) => {
  try {
    return await putData(`${api}/loveIdea`, { competitionId, ideaId });
  } catch (error) {
    console.log(error);
  }
};

export const unLoveIdea = async ({
  competitionId,
  ideaId,
}: {
  competitionId: string | undefined;
  ideaId: string | undefined;
}) => {
  try {
    return await putData(`${api}/unLoveIdea`, { competitionId, ideaId });
  } catch (error) {
    console.log(error);
  }
};

export const deleteIdea = async ({
  ideaId,
  competitionId,
}: {
  competitionId: string | undefined;
  ideaId: string | undefined;
}) => {
  try {
    await deleteData(`${api}/delete/${competitionId}/${ideaId}`);
  } catch (error) {
    console.log(error);
  }
};

export const prev = async ({
  competitionId,
  ideaId,
}: {
  competitionId: string;
  ideaId: string;
}) => {
  try {
    return await getData(`${api}/next/${competitionId}/${ideaId}`);
  } catch (error) {
    console.log(error);
  }
};

export const next = async ({
  competitionId,
  ideaId,
}: {
  competitionId: string;
  ideaId: string;
}) => {
  try {
    return await getData(`${api}/prev/${competitionId}/${ideaId}`);
  } catch (error) {
    console.log(error);
  }
};

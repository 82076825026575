import participantModel from '../../models/participants';
import ParticipantList from '../ParticipantsList';
import { Modal } from '../Modal';
import styled from 'styled-components';
import { TextWrapper } from '../TextWrapper';
import { Anchor } from '../../elements/Anchor';
import { Button } from '../../elements/Button';
import copy from '../../utils/copy';
import { useState } from 'react';
import { updateCompetitionPin } from '../../services/competitionService';
import { isHost } from '../../utils/checkRole';
import { useAuthContext } from '../../context/Auth';
import { useChangeContext } from '../../context/ChangeDetection';
import { useTranslation } from 'react-i18next';
import { useIonToast } from '@ionic/react';

type GeneratePinProps = {
  participants: Array<participantModel>;
  competitionPin?: string;
  competitionId: string;
  hostId: string;
};

const Content = styled.div`
  position: relative;
  width: 700px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 5px;
`;
export const GeneratePin = ({
  competitionId,
  competitionPin,
  participants,
  hostId,
}: GeneratePinProps) => {
  const copyPrefix = `${process.env.REACT_APP_BASE_URL}/home#`;
  const [pin, setPin] = useState(competitionPin);
  const { currentUser } = useAuthContext();
  const [present] = useIonToast();

  const { t } = useTranslation();
  const generateNewPin = async (id) => {
    const response = await updateCompetitionPin({ competitionId: id });
    setPin(response.data);
    setChange(new Date());
  };

  const { setChange } = useChangeContext();

  return (
    <Modal
      children={
        <Content>
          <TextWrapper
            children={
              <>
                <p>
                  {t('joinModal.competitionPinText')} {pin}
                </p>
                <p>{t('joinModal.title')}</p>
              </>
            }
          />
          {pin && (
            <>
              <Anchor text={`${copyPrefix}${pin}`} />
              <br />
            </>
          )}
          <ButtonWrapper>
            {pin && (
              <>
                <Button
                  onClick={() => {
                    copy(`${copyPrefix}${pin}`);

                    present({
                      message: t('copyLink'),
                      duration: 2000,
                      position: 'bottom',
                      color: 'warning',
                    });
                  }}
                  text={t('button.copy')}
                />
              </>
            )}

            {isHost({ hostId, authId: currentUser?.uid }) && (
              <Button
                text={t('button.generate')}
                onClick={() => generateNewPin(competitionId)}
              />
            )}
          </ButtonWrapper>
          <ParticipantList
            competitionId={competitionId}
            hostId={hostId}
            participants={participants}
          />
        </Content>
      }
    />
  );
};

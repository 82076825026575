import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from '../Modal';
import { Image } from '../../elements/Image';
import css from '@styled-system/css';
import { Headline } from '../../elements/Headlines';
import { Input } from '../../elements/Input';
import { Button } from '../../elements/Button';
import { useTranslation } from 'react-i18next';

type WelcomeTypes = {
  className?: string;
  join: (pin) => void;
};

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 400px;
  ${css({
    width: [],
    height: [],
  })}
`;
const LogoImage = styled(Image)`
  height: 100%;
  width: 200px;
  position: relative;
`;

const ImageContainer = styled.div`
  height: 20%;
  margin-top: 50px;
`;

const Flex = styled.div`
  margin-top: 30px;
  display: flex;
`;

export const Welcome = ({ join }: WelcomeTypes) => {
  const [pin, setPin] = useState('');

  const inputChangeEvent = (event: any) => {
    setPin(event.target.value);
  };

  const { t } = useTranslation();

  const Logo = '/assets/logo/Logo_live.png';
  return (
    <Modal
      children={
        <Content>
          <ImageContainer>
            <LogoImage src={Logo} />
          </ImageContainer>
          <Headline title={t('welcomeModal.pinToJoin')} size={'h3'} />
          <Flex>
            <Input
              onChange={inputChangeEvent}
              label={t('welcomeModal.placePIN')}
              type="text"
            />
            <Button onClick={() => join(pin)} text={t('button.join')} />
          </Flex>
        </Content>
      }
    />
  );
};

import styled from 'styled-components';
import { HeadlineType } from '../types/headline';

const H1 = styled.h1``;
const H2 = styled.h2``;
const H3 = styled.h3``;
const H4 = styled.h4``;
const H5 = styled.h5``;
const H6 = styled.h6``;

const Headlines = {
    h1: H1,
    h2: H2,
    h3: H3,
    h4: H4,
    h5: H5,
    h6: H6,
};
export const Headline = ({ title, size, className, ...rest }: HeadlineType) => {
    const Head = Headlines[size];
    return (
        <Head className={className} {...rest}>
            {title}
        </Head>
    );
};

import styled from 'styled-components';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IonBadge } from '@ionic/react';
import { NotificationType } from '../types/navigation';
import { Button } from '../elements/Button';
import { useAppContext } from '../context/Shared';
import { getUserDoc } from '../services/userService';
import '../theme/variables.scss';
import { useEffect, useState } from 'react';
import { useChangeContext } from '../context/ChangeDetection';

const NotificationWrapper = styled.div`
  position: relative;
  overflow: visible !important;
`;

const StyledIonBadge = styled(IonBadge)`
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: var(--ion-color-danger);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  z-index: 10;
`;

const NotificationInnerWrapper = styled.div`
  padding: 1%;
`;

export const Notification = ({
  currentUser,
  onClick,
}: NotificationType | any) => {
  const sharedContext = useAppContext();
  const { change } = useChangeContext();
  const isDesktop = sharedContext?.device?.isDesktop;
  const [userDoc, setUserDoc] = useState(currentUser);
  useEffect(() => {
    const getCurrentUser = async () => {
      const updatedUserDoc = await getUserDoc(currentUser.uid);
      setUserDoc(updatedUserDoc);
    };
    getCurrentUser();
  }, [change, currentUser]);
  return (
    <>
      {isDesktop && (
        <NotificationWrapper>
          {userDoc?.notificationCount > 0 && (
            <StyledIonBadge>{userDoc.notificationCount}</StyledIonBadge>
          )}
          <NotificationInnerWrapper>
            <Button onClick={onClick} fill="clear">
              <NotificationsIcon fontSize="large" />
            </Button>
          </NotificationInnerWrapper>
        </NotificationWrapper>
      )}
    </>
  );
};

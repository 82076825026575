import {
  ContentSpaceWrapper,
  StyledIonContent,
} from '../elements/genericWrappers';
import { SectionBar } from '../components/SectionBar';
import CardWrapper from '../elements/CardWrapper';
import { Card } from '../components/Card';
import { IdeaModel } from '../models/idea';
import { useFetchIdeas } from '../hooks/ideas';
import { BigCard } from '../components/BigCard';
import { TransparentCardIdea } from '../components/TransparentCardIdea';
import styled from 'styled-components';
import css from '@styled-system/css';
import { IonRouterLink, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import { useGlobalModalContext } from '../context/GlobalModal';
import { useFetchChallenge } from '../hooks/challenges';
import { BadgeWrapper, UnBadge } from '../elements/unReadBubble';
import { useAuthContext } from '../context/Auth';
import { useAppContext } from '../context/Shared';
import { useEffect } from 'react';
import ErrorBoundary from '../components/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import { SpinnerLoading } from '../elements/SpinnerLoading';

const StyledCard = styled(Card)`
  ${css({
    maxWidth: ['350px', '350px', '300px', '350px'],
  })}
`;
const StyledContentWrapper = styled(ContentSpaceWrapper)`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  ${css({
    padding: ['10px 10px', '10px 10px', '10px 10px', '10px 10px', '10px 10px'],
  })};
`;

const BackButton = styled(IonIcon)`
  font-size: 30px;
  margin-top: 25px;
  margin: 1%;
  float: right;
  cursor: pointer;
  right: 0;
  margin-left: auto;
$`;

const ButtonNoPadding = styled.button`
  padding: 0;
`;
const IdeasPage = ({ match, history }) => {
  const competitionId = match.params.challengeId;
  const { showModal } = useGlobalModalContext();
  const { currentUser } = useAuthContext();
  const { ideas } = useFetchIdeas(competitionId);
  const competition = useFetchChallenge(competitionId);
  const { t } = useTranslation();
  const { device } = useAppContext();

  const { setCurrentRouteParams } = useAppContext();

  useEffect(() => {
    if (setCurrentRouteParams) {
      setCurrentRouteParams({ challengeId: competitionId, ideaId: '' });
    }
    if (!competitionId) {
      window.location.reload();
    }
  }, []);
  try {
    if (
      competition &&
      Object.keys(competition).length === 0 &&
      Object.getPrototypeOf(competition) === Object.prototype
    ) {
      throw new Error(t('siteNotFound'));
    }
    return (
      <StyledIonContent>
        {competition ? (
          <>
            <StyledContentWrapper>
              <BackButton onClick={() => history.go(-1)} icon={close} />

              <BigCard
                competitionId={competitionId}
                competitionPin={competition.PIN}
                title={competition.title}
                description={competition.description}
                backgroundImage={competition.backgroundImage}
                cardState={competition.daysLeft === 0 ? 'inactive' : 'active'}
                daysLeft={competition.daysLeft}
                ideaCount={competition.ideaCount}
                participants={competition.participants}
                video={competition.competitionVideo}
                user={{
                  email: undefined,
                  name: competition.host?.name || '',
                  profilePicture: competition.host?.profilePicture,
                }}
              />
            </StyledContentWrapper>
            <ContentSpaceWrapper>
              <SectionBar
                type={'blue'}
                title={t('ideas')}
                disable={!device?.isDesktop || competition.ended}
              >
                <CardWrapper>
                  {device?.isDesktop && !competition.ended && (
                    <ButtonNoPadding
                      onClick={() =>
                        showModal('COMPETITION_IDEA_MODAL', {
                          competition,
                          actionType: 'Create',
                          objectType: 'Idea',
                        })
                      }
                    >
                      <TransparentCardIdea />
                    </ButtonNoPadding>
                  )}

                  {ideas ? (
                    ideas.map((idea: IdeaModel, index) => {
                      return (
                        <IonRouterLink
                          key={'idea-' + index}
                          href={`/idea/${competition.id}/${idea.id}`}
                        >
                          <BadgeWrapper>
                            <UnBadge
                              unReadBy={idea.unReadBy}
                              authId={currentUser?.uid}
                            />
                            <StyledCard
                              title={idea.title}
                              description={idea.description}
                              backgroundImage={idea.ideaImage}
                              ideaId={idea.id}
                              video={idea.ideaVideo}
                              commentCount={idea.commentCount}
                              cardType={'idea'}
                              cardColor="blue"
                              user={{
                                email: undefined,
                                name: idea.author?.name || '',
                                profilePicture: idea?.author?.profilePicture,
                                id: idea.author?.id,
                              }}
                              lovedBy={idea.lovedBy}
                              key={index}
                              competitionId={competitionId}
                            />
                          </BadgeWrapper>
                        </IonRouterLink>
                      );
                    })
                  ) : (
                    <SpinnerLoading small={false} fullpage={true} />
                  )}
                </CardWrapper>
              </SectionBar>
            </ContentSpaceWrapper>
          </>
        ) : (
          <SpinnerLoading small={true} fullpage={false} />
        )}
      </StyledIonContent>
    );
  } catch (error) {
    return <ErrorBoundary error={error} />;
  }
};
export default IdeasPage;

import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { FCM } from '@capacitor-community/fcm';

import { postData } from '../utils/requestHandlers';
import console from '../utils/log';
import { isNative } from '../utils/isNative';
import { useEffect } from 'react';
const endpoint = process.env.REACT_APP_API;
const route = '/devices';
const api = endpoint + route;

export const fcmSaveToken = async (tokenFromCapacitator?) => {
  const tokenToSave = tokenFromCapacitator;

  await saveToken(tokenToSave);
};

const saveToken = async (token: string) => {
  try {
    return postData(`${api}/saveToken/${token}`);
  } catch (error) {
    console.log(error);
  }
};

export const usePushNotifications = () => {
  useEffect(() => {
    try {
      if (isNative()) {
        PushNotifications.checkPermissions().then((res) => {
          if (res.receive !== 'granted') {
            PushNotifications.requestPermissions().then((res) => {
              if (res.receive === 'denied') {
              } else {
                register();
              }
            });
          } else {
            register();
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, []);
};

const register = () => {
  // Register with Apple / Google to receive push via APNS/FCM
  PushNotifications.register();

  // On success, we should be able to receive notifications
  PushNotifications.addListener('registration', async (token: Token) => {
    if (token.value) {
      const fcmToken = await FCM.getToken();
      await fcmSaveToken(fcmToken.token);
    }
  });

  // Some issue with our setup and push will not work
  PushNotifications.addListener('registrationError', (error: any) => {});

  // Show us the notification payload if the app is open on our device
  PushNotifications.addListener(
    'pushNotificationReceived',
    (notification: PushNotificationSchema) => {}
  );

  // Method called when tapping on a notification
  PushNotifications.addListener(
    'pushNotificationActionPerformed',
    (notification: ActionPerformed) => {}
  );
};

import {
  EmailField,
  FirstNameField,
  LastNameField,
  PasswordField,
  ProfileImageField,
  ErrorMessage,
  NavigationButton,
  Submit,
  StyledForm,
  NavigationContainer,
  TextNavigationButton,
} from '../components/FormComponents';
import {
  LoginWithEmailAndPassword,
  SignUpWithEmailAndPassword,
} from '../services/authService';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthContext } from '../context/Auth';
import { useGlobalModalContext } from '../context/GlobalModal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Anchor } from '../elements/Anchor';

const ResetPasswordBtn = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const FirebaseForm = ({ setHeader, setForm }) => {
  const [type, setType] = useState('login');
  const [errorCode, setErrorCode] = useState('');
  const { hideModal } = useGlobalModalContext();
  const { t } = useTranslation();

  const { setCurrentUser } = useAuthContext();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm();
  useEffect(() => {
    setHeader(t('pleaseLogin'));
    setErrorCode('');
  }, [type]);
  const onSubmit = async (data) => {
    try {
      if (type === 'login') {
        await LoginWithEmailAndPassword(data);
      }
      if (type === 'signin') {
        const userDoc = await SignUpWithEmailAndPassword(data);
        setCurrentUser(userDoc);
      }
      hideModal();
    } catch (error: any) {
      const { code } = error;
      setErrorCode(code);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      {type === 'login' ? (
        <>
          <EmailField register={register} errors={errors} />
          <PasswordField register={register} errors={errors} />
          <Submit text={t('button.login')} />
          <ResetPasswordBtn onClick={() => setForm('FIREBASE_RESET_PASSWORD')}>
            {' '}
            {t('button.forgotPassword')}{' '}
          </ResetPasswordBtn>
          <NavigationContainer>
            {t('noProfileSignUpHere')}{' '}
            <Anchor text={t('here')} onClick={() => setType('signin')} />
          </NavigationContainer>
        </>
      ) : (
        <>
          <FirstNameField register={register} errors={errors} />
          <LastNameField register={register} errors={errors} />
          <EmailField register={register} errors={errors} />
          <PasswordField register={register} errors={errors} />
          <ProfileImageField register={register} watch={watch} />
          <Submit disabled={isSubmitting} text={t('button.signup')} />
          <NavigationContainer>
            {t('profileAlreadyLogin')}{' '}
            <Anchor text={t('here')} onClick={() => setForm('')} />
          </NavigationContainer>
        </>
      )}
      {errorCode && <ErrorMessage error={errorCode} />}
    </StyledForm>
  );
};

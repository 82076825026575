import {
  deleteData,
  getData,
  postData,
  putData,
} from '../utils/requestHandlers';
import console from '../utils/log';

const endpoint = process.env.REACT_APP_API;
const route = '/comments';
const api = endpoint + route;

export const getComment = async ({
  competitionId,
  commentId,
  ideaId,
}: {
  competitionId: string;
  commentId: string;
  ideaId: string;
}) => {
  try {
    return await getData(
      `${api}/getSingle/${competitionId}/${ideaId}/${commentId}`
    );
  } catch (error) {
    console.log(error);
  }
};

export const getAllComments = async ({
  competitionId,
  ideaId,
}: {
  competitionId?: string;
  ideaId?: string;
}) => {
  try {
    return await getData(`${api}/getAll/${competitionId}/${ideaId}`);
  } catch (error) {
    console.log(error);
  }
};

export const createComment = async (comment: any) => {
  try {
    return await postData(`${api}/create`, comment);
  } catch (error) {
    console.log(error);
  }
};

export const updateComment = async (comment: any) => {
  try {
    return await putData(`${api}/update`, comment);
  } catch (error) {
    console.log(error);
  }
};

export const deleteComment = async ({
  competitionId,
  ideaId,
  commentId,
}: {
  commentId: string;
  ideaId: string;
  competitionId: string;
}) => {
  try {
    return await deleteData(
      `${api}/delete/${competitionId}/${ideaId}/${commentId}`
    );
  } catch (error) {
    console.log(error);
  }
};

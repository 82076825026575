import Tooltip from '@mui/material/Tooltip';
type TooltipProps = {
  text: string;
  children: JSX.Element;
};

const TooltipComponent = ({ text, children }: TooltipProps) => {
  return <Tooltip title={text}>{children}</Tooltip>;
};
export default TooltipComponent;

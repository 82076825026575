import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  connectAuthEmulator,
  initializeAuth,
  indexedDBLocalPersistence,
  Auth,
} from 'firebase/auth';
import {
  getStorage,
  ref,
  uploadBytes,
  connectStorageEmulator,
  FirebaseStorage,
} from 'firebase/storage';

import { FirebaseAuthentication as nativeAuth } from '@ionic-native/firebase-authentication';
import { Config } from './firebaseConfig';

const app = initializeApp(Config.firebase);
let auth: Auth;
let storage: FirebaseStorage;

if (Capacitor.isNativePlatform()) {
  auth = initializeAuth(app, {
    persistence: indexedDBLocalPersistence,
  });
  storage = getStorage(app);
} else {
  storage = getStorage();
  auth = getAuth(app);
}
if (process.env.REACT_APP_ENV === 'local') {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectStorageEmulator(storage, 'localhost', 9199);
}

export { app, auth, nativeAuth, storage };
